import React, { useCallback, useMemo } from 'react'
import { OpportunityVisibility } from 'Event/interfaces'
import { FormModal, FormModalScreen } from 'components/FormModal'
import { useGroups } from 'group/hooks/useGroups'
import { Group } from 'Event/components/opportunity/GroupPicker/types'
import { useEnabledGroups, useOptions } from 'Event/components/opportunity/GroupPicker/hooks'
import { useFeatureEnabled } from 'core/feature/hooks'
import Loading from 'civic-champs-shared/core/Loading'
import {
  AssociationType,
  EventGroup,
  OpportunityOccurrencePersonGroup,
  OpportunityResponseWithRecurrenceInfo,
} from 'Event/interfaces/interfaceCreateEditEvent'
import moment from 'moment-timezone'
import { Field, Form, Formik } from 'formik'
import cn from 'classnames'
import {
  StyledKeyboardDatePicker,
  StyledKeyboardTimePicker,
} from '../../civic-champs-shared/formik/components/StyledFormikDateTimePickers'
import { useAddEditEventStyles } from '../hooks/useAddEditEventStyles'
import { StyledInput } from '../../civic-champs-shared/formik/components/StyledInput'
import { CkEditorStyledField } from '../../civic-champs-shared/formik/components/CkEditorStyledField'
import RadioGroupFormField from '../../components/RadioGroupFormField'
import { StyledAutocomplete } from '../../components/StyledAutocomplete'
import { AddressField } from '../../civic-champs-shared/formik/components'
import { FormData, offerInitialValues, validationSchema } from '../helpers/add-offer-helpers'
import { useDateTimeUtils } from '../../civic-champs-shared/utils/useDateTimeUtils'
import { mapGeofencingToEventGeofencing } from '../../utils/event'
import { mapToEventGroup } from '../scenes/edit-event'
import { DEFAULT_GEOFENCING } from '../../civic-champs-shared/constants/GEO_DATA'

export const AddEditOfferPrompt = ({
  showing,
  close,
  complete,
  offer,
  visibilityGroups,
}: {
  showing: boolean
  complete: (value: any) => void
  close: () => void
  offer?: OpportunityResponseWithRecurrenceInfo
  visibilityGroups?: OpportunityOccurrencePersonGroup[]
}) => {
  const { unfakeAsLocalTimeForUIAsString, fakeAsLocalTimeForUI, compileDateTime } = useDateTimeUtils()
  const { groups: initialGroups, loading: groupsLoading } = useGroups() as { groups: Group[]; loading: boolean }
  const groups = useEnabledGroups(initialGroups)
  const options = useOptions({ groups, associationType: AssociationType.EVENT_PRIVATE_TO_MEMBERS, value: [] })
  const groupsEnabled = useFeatureEnabled('Groups')
  const classes = useAddEditEventStyles()
  const initialValues = useMemo(() => {
    if (offer) {
      return {
        name: offer.name,
        description: offer.description || '',
        instructions: offer.instructions || '',
        address: {
          line1: offer.address || '',
          line2: '',
          city: offer.city || '',
          state: offer.state || '',
          zip: offer.zip || '',
          geofencing: offer.geofencing,
          country: '',
          county: '',
          utcOffset: 0,
        },
        locationIsAddress: (offer.locationIsAddress ? 'true' : 'false') as 'true' | 'false',
        locationDetails: offer.locationDetails,
        startDate: fakeAsLocalTimeForUI(offer.startsAt),
        startTime: fakeAsLocalTimeForUI(offer.startsAt),
        endDate: offer.endsAt ? fakeAsLocalTimeForUI(offer.endsAt) : null,
        endTime: offer.endsAt ? fakeAsLocalTimeForUI(offer.endsAt) : null,
        trackSignups: (offer.trackSignups ? 'true' : 'false') as 'true' | 'false',
        isOffer: true,
        visibility: offer.visibility,
        visibilityGroups:
          visibilityGroups
            ?.filter(
              (group: OpportunityOccurrencePersonGroup) =>
                group.associationType === AssociationType.EVENT_PRIVATE_TO_MEMBERS,
            )
            .map(mapToEventGroup) || [],
      } as FormData
    } else {
      return offerInitialValues
    }
  }, [fakeAsLocalTimeForUI, offer, visibilityGroups])

  const handleSubmit = useCallback(
    ({ startDate, endDate, startTime, endTime, address, locationDetails, visibilityGroups, ...values }: FormData) => {
      const locationIsAddress = values.locationIsAddress === 'true'
      complete({
        ...values,
        locationDetails: locationIsAddress ? undefined : locationDetails,
        locationIsAddress,
        trackSignups: values.trackSignups === 'true',
        startsAt: unfakeAsLocalTimeForUIAsString(compileDateTime(startDate, startTime)),
        endsAt: endDate && endTime ? unfakeAsLocalTimeForUIAsString(compileDateTime(endDate, endTime)) : undefined,
        geofencing: mapGeofencingToEventGeofencing(locationIsAddress ? address.geofencing : DEFAULT_GEOFENCING),
        address: locationIsAddress ? address.description : undefined,
        groups: visibilityGroups,
      })
    },
    [compileDateTime, complete, unfakeAsLocalTimeForUIAsString],
  )

  if (groupsLoading) {
    return <Loading />
  }

  return (
    <FormModal id="offer-prompt" showing={showing} close={close} title={offer ? 'Edit Offer' : 'Create Offer'}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnChange
        isInitialValid={false}
      >
        {({ submitForm, isSubmitting, values }) => {
          return (
            <Form>
              <FormModalScreen
                onBack={close}
                onNext={submitForm}
                nextText={offer ? 'Save' : 'Add'}
                backText="Cancel"
                disabled={isSubmitting}
              >
                <div className={classes.section}>
                  <span className={classes.sectionTitle}>Offer Details:</span>
                  <Field
                    name="name"
                    component={StyledInput}
                    label={'Offer Name *'}
                    placeholder="Write the offer’s name here..."
                  />

                  <Field
                    id="offer-description"
                    name="description"
                    label="Offer Description"
                    placeholder="Describe the offer here..."
                    component={CkEditorStyledField}
                  />
                </div>
                <div className={cn(classes.section, classes.subSection)}>
                  <span className={classes.sectionTitle}>Offer Scheduling:</span>

                  <div className={classes.dateTimePickers}>
                    <div className={classes.dateTimePicker}>
                      <Field
                        inputVariant="outlined"
                        label="Start Date *"
                        higher
                        component={StyledKeyboardDatePicker}
                        name="startDate"
                      />
                    </div>

                    <div className={classes.dateTimePicker}>
                      <Field
                        higher
                        inputVariant="outlined"
                        label="End Date"
                        component={StyledKeyboardDatePicker}
                        name="endDate"
                        clearable
                        minDate={moment().startOf('day').toDate()}
                        minDateMessage={'The end date cannot occur before the start date.'}
                      />
                    </div>
                  </div>

                  <div className={classes.dateTimePickers}>
                    <div className={classes.dateTimePicker}>
                      <Field
                        inputVariant="outlined"
                        label="Start Time *"
                        higher
                        component={StyledKeyboardTimePicker}
                        name="startTime"
                      />
                    </div>

                    <div className={classes.dateTimePicker}>
                      <Field
                        higher
                        inputVariant="outlined"
                        label="End Time"
                        clearable
                        component={StyledKeyboardTimePicker}
                        name="endTime"
                      />
                    </div>
                  </div>
                </div>
                {groupsEnabled && (
                  <div className={cn(classes.section, classes.noMargin, classes.subSection)}>
                    <span className={classes.sectionTitle}>Calendar Visibility:</span>
                    <RadioGroupFormField
                      name="visibility"
                      disabled={isSubmitting}
                      options={[
                        {
                          label: 'This is a public offer',
                          value: OpportunityVisibility.PUBLIC,
                        },
                        {
                          label: 'This offer is only visible to selected Groups',
                          value: OpportunityVisibility.SELECT_GROUPS_ONLY,
                        },
                      ]}
                    />
                    {values.visibility === OpportunityVisibility.SELECT_GROUPS_ONLY && (
                      <Field
                        className={classes.subItem}
                        name="visibilityGroups"
                        placeholder="Select Group (s)"
                        label="Groups"
                        component={StyledAutocomplete}
                        options={options}
                        getOptionLabel={({ name, closed, approvedMembersOnly }: EventGroup) =>
                          !closed ? name : approvedMembersOnly ? `${name} - Approved members` : `${name} - Applicants`
                        }
                        disabled={isSubmitting}
                        multiple
                        notched={true}
                      />
                    )}
                  </div>
                )}
                <div className={cn(classes.section, classes.subSection)}>
                  <span className={classes.sectionTitle}>Location:</span>
                  <RadioGroupFormField
                    name="locationIsAddress"
                    disabled={isSubmitting}
                    options={[
                      {
                        label: 'This offer takes place at a specific location',
                        value: 'true',
                      },
                    ]}
                  />
                  {values.locationIsAddress === 'true' && (
                    <Field
                      className={classes.subItem}
                      fullWidth
                      variant="outlined"
                      labelShrink
                      label="Offer Location *"
                      placeholder="Select your offer’s address here..."
                      component={AddressField}
                      name="address"
                      showRadius
                      vertical
                      halfMap
                      hideMap={!values.address.line1}
                    />
                  )}
                  <div className={cn({ [classes.unGap]: values.locationIsAddress === 'false' })}>
                    <RadioGroupFormField
                      name="locationIsAddress"
                      disabled={isSubmitting}
                      options={[
                        {
                          label: 'This offer is not tied to an address',
                          value: 'false',
                        },
                      ]}
                    />
                  </div>
                  {values.locationIsAddress === 'false' && (
                    <Field
                      component={StyledInput}
                      name="locationDetails"
                      className={classes.subItem}
                      label={'Offer Location'}
                      placeholder="Enter offer location details here..."
                      maxLength={100}
                    />
                  )}
                </div>
                <div className={cn(classes.section, classes.noMargin, classes.subSection)}>
                  <span className={classes.sectionTitle}>Sign-Ups:</span>
                  <RadioGroupFormField
                    name="trackSignups"
                    disabled={isSubmitting}
                    options={[
                      {
                        label: 'This offer does not track sign-ups',
                        value: 'false',
                      },
                      {
                        label: 'This offer tracks sign-ups',
                        value: 'true',
                      },
                    ]}
                  />
                  {values.trackSignups === 'true' && (
                    <Field
                      id="offer-instructions"
                      name="instructions"
                      label="Post-Signup Information"
                      placeholder="Provide information after sign-up here..."
                      component={CkEditorStyledField}
                    />
                  )}
                </div>
              </FormModalScreen>
            </Form>
          )
        }}
      </Formik>
    </FormModal>
  )
}

export default AddEditOfferPrompt
