import React, { useCallback, useState } from 'react'
import pickerData from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

import Pubnub, { APNS2Target } from 'pubnub'
import {
  Chat,
  MessageInput,
  MessageList,
  TypingIndicator,
  useChannelMembers,
  MessagePayload,
} from '@pubnub/react-chat-components/dist/index'

import format from 'civic-champs-shared/utils/format'

import 'chats/components/pubnub-restyle.scss'
import { Chat as IChat } from 'chats/interfaces'
import { useCurrentProfile } from 'auth/hooks'
import { ProductionChatTarget } from '../interfaces'

export default function ChatDialog({ currentChannel }: { currentChannel: IChat }): JSX.Element {
  const [theme] = useState<'light' | 'dark'>('light')
  const profile = useCurrentProfile()
  const excludedDevices = profile.user.deviceTokens || []

  /**
   * All data related to Users, Channels and Memberships is stored within PubNub Objects API
   * It can be easily accessed using React Chat Components hooks
   */

  const [channelMembers, , , totalChannelMembers] = useChannelMembers({
    channel: currentChannel.id,
    include: { customUUIDFields: true },
  })

  /**
   * Handling publish errors
   */
  const handleError = (e: any) => {
    if (
      (e.status?.operation === 'PNPublishOperation' && e.status?.statusCode === 403) ||
      e.message.startsWith('Publish failed')
    ) {
      alert(
        'Your message was blocked. Perhaps you tried to use offensive language or send an image that contains nudity?',
      )
    }

    console.warn(e)
  }

  const onBeforeSendMessage = useCallback(
    (message: MessagePayload) => {
      const title = format.name(profile.user)
      const subtitle = currentChannel?.name ?? ''
      const body = `${subtitle}: ${message.text}`
      const builder = Pubnub.notificationPayload(title, body)
      builder.apns.configurations = [
        {
          targets: [ProductionChatTarget as APNS2Target],
        },
      ]

      builder.subtitle = subtitle
      builder.debugging = process.env.NODE_ENV !== 'production'

      const messagePayload = builder.buildPayload(['apns2', 'fcm']) as { pn_gcm: object; pn_apns: object }

      const { id, name } = currentChannel
      const { topic } = ProductionChatTarget
      const { pn_apns, pn_gcm } = messagePayload

      const payload = {
        ...message,
        pn_apns: {
          ...pn_apns,
          headers: {
            'apns-push-type': 'alert',
            'apns-topic': topic,
            'apns-priority': '10',
          },
          pn_exceptions: excludedDevices,
        },
        // Migrated to FCM HTTP v1,
        // see https://www.pubnub.com/docs/general/resources/migration-guides/legacy-fcm-migration-guide
        pn_fcm: {
          ...pn_gcm,
          android: {
            collapse_key: 'group',
            data: {
              chatId: id,
              chatName: name,
            },
          },
        },
      }

      return payload
    },
    [currentChannel, excludedDevices, profile.user],
  )

  /**
   * Rendered markup is a mixture of PubNub Chat Components (Chat, ChannelList, MessageList,
   * MessageInput, MemberList) and some elements to display additional information and to handle
   * custom behaviors (channels modal, showing/hiding panels, responsive design)
   */
  return (
    <div className={`app-moderated app-moderated--${theme}`}>
      {/* Be sure to wrap Chat component in PubNubProvider from pubnub-react package.
        In this case it's done in the index.tsx file */}
      {/* Current uuid is passed to channels prop to subscribe and listen to User metadata changes */}
      <Chat theme={theme} users={channelMembers} currentChannel={currentChannel.id} onError={handleError}>
        <div className="chat-window">
          <div className="channel-info">
            <span>
              <strong>{currentChannel.name || currentChannel.id}</strong>
              <p>{totalChannelMembers} members</p>
            </span>
            <hr />
          </div>
          <MessageList
            fetchMessages={25}
            enableReactions={true}
            reactionsPicker={<Picker data={pickerData} theme={theme} />}
          />
          <TypingIndicator />
          <hr />
          <MessageInput
            typingIndicator
            fileUpload="image"
            emojiPicker={<Picker data={pickerData} theme={theme} />}
            placeholder={'Send message'}
            onBeforeSend={onBeforeSendMessage}
          />
        </div>
      </Chat>
    </div>
  )
}
