import { map, findWhere, size, isEmpty } from 'lodash'
import React, { useMemo } from 'react'
import { Grid, Typography, Box, Button, Tooltip, makeStyles } from '@material-ui/core'
import CallMergeIcon from '@material-ui/icons/CallMerge'
import InfoIcon from '@material-ui/icons/InfoOutlined';
import matchSorter from 'match-sorter'
import { useTable, useGlobalFilter, usePagination, useFilters, useSortBy, useRowSelect } from 'react-table'
import { ANCHOR_LEFT } from 'react-dates/constants'

import Loading from 'components/Loading'
import DateRangeFilter from 'components/filter/DateRangeFilter'
import { useDateRangeFilter } from 'filtering/hooks'
import { PhoneNumberCell, ContactCell, DateCell, ViewCell } from 'core/table/cells'
import Search from 'core/table/components/Search'
import PagedTable from 'core/table/components/PagedTable'
import DEFAULT_FILTERS from 'core/table/filters'
import { contactAccessor } from 'core/table/utils'
import { createContactSort } from 'components/table/sort'
import { useHasRole } from 'civic-champs-shared/auth/hooks'
import { INTERNAL_SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import useSelectColumn from 'core/table/table-hooks/useSelectColumn'
import { useMergePeople } from 'people/merge-people';

const useStyles = makeStyles(theme => ({
  mergeInfo: {
    verticalAlign: 'middle',
    marginLeft: '5px',
    color: theme.palette.action.disabled
  }
}))

const columns = [
  { id: 'id', Header: 'ID', accessor: 'id' },
  { id: 'firstName', Header: 'First Name', accessor: 'givenName' },
  { id: 'lastName', Header: 'Last Name', accessor: 'familyName' },
  {
    id: 'email',
    Header: 'Email',
    sortType: createContactSort('email'),
    accessor: contactAccessor({
      valuePath: 'email',
      verificationStatusPath: 'emailVerified',
      type: 'email',
    }),
    Cell: ContactCell,
  },
  {
    id: 'mobile',
    Header: 'Mobile',
    sortType: createContactSort('mobile'),
    accessor: contactAccessor({
      valuePath: 'phoneNumber',
      verificationStatusPath: 'phoneNumberVerified',
      type: 'phone',
    }),
    Cell: ContactCell,
  },
  {
    id: 'homePhoneNumber',
    Header: 'Home',
    accessor: 'homePhoneNumber',
    Cell: PhoneNumberCell,
  },
  {
    id: 'registration',
    Header: 'Login?',
    accessor: person => (person.cognitoSub ? 'Yes' : 'No'),
  },
  {
    id: 'createdAt',
    Header: 'Added',
    accessor: 'createdAt',
    Cell: DateCell,
    filter: 'dateRange',
  },
  {
    Header: '',
    id: 'view',
    Cell: ViewCell(id => `/people/${id}`),
  },
]

const fuzzyMatchFilter = (rows, id, filterValue) =>
  matchSorter(rows, filterValue, {
    keys: ['original.givenName', 'original.familyName', 'original.email', 'original.phoneNumber', 'original.homePhoneNumber', 'original.id'],
  })

fuzzyMatchFilter.autoRemove = value => !value

const filterTypes = {
  fuzzyMatch: fuzzyMatchFilter,
  ...DEFAULT_FILTERS,
}

export const PeopleOverview = props => {
  const { loading, people, title, refresh } = props
  const [dateRange, setDateRange] = useDateRangeFilter()

  const isInternalSuperAdmin = useHasRole(INTERNAL_SUPER_ADMIN)
  const mergePeople = useMergePeople()

  const table = useTable(
    {
      data: useMemo(() => people, [people]),
      columns,
      filterTypes,
      globalFilter: 'fuzzyMatch',
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useSelectColumn
  )

  const handleQueryChange = query => {
    table.setGlobalFilter(query || undefined)
  }

  const handleDateRangeChange = newRange => {
    setDateRange(newRange)
    table.setFilter('createdAt', newRange)
  }

  const selectedPeopleCount = size(table.state.selectedRowIds)
  const handleMerge = async () => {
    if(selectedPeopleCount != 2) return;

    const people = map(table.selectedFlatRows, row => row.original)
    await mergePeople(people)
    refresh()
  }

  const classes = useStyles()

  return (
    <div>
      <Grid container direction="column" spacing={3}>
        <Grid container xs={12} item justify="space-between" alignItems="center" direction="row">
          <Grid item xs={6}>
            <Typography variant="h4">{title}</Typography>
          </Grid>
          <Grid xs={6} container item alignItems="center" justify="flex-end" direction="row">
            <Search onQueryChange={handleQueryChange} variant="outlined" placeholder="Search" />
            <Box style={{ padding: 4, border: 'solid 1px rgba(179, 179, 179)', borderRadius: 4, marginLeft: '10px'}}>
              <DateRangeFilter
                initialValues={dateRange}
                onFilterApplied={handleDateRangeChange}
                anchorDirection={ANCHOR_LEFT}
                noBorder={true}
              />
            </Box>
          </Grid>
        </Grid>
        {isInternalSuperAdmin && (<Grid item container spacing={1} alignItems="center" justify="flex-start">
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              disabled={selectedPeopleCount !== 2}
              onClick={handleMerge}
            >
              <CallMergeIcon style={{ marginRight: 5 }} />
              Merge
            </Button>
            {selectedPeopleCount > 2 && (<>
              <Tooltip title={`${selectedPeopleCount} selected.  Select only 2 for merge.`}>
                <InfoIcon className={classes.mergeInfo} />
              </Tooltip>
            </>)}
          </Grid>
        </Grid>)}
        <Grid item>
          {loading ? (
            <Loading />
          ) : (
            <>
              <PagedTable {...table} />
            </>
          )}
        </Grid>
      </Grid>
    </div>
  )
}
