import React, { useCallback, useEffect } from 'react'
import PageHeader from 'App/components/PageHeader'
import useGetColumnState from 'core/table/table-hooks/useGetColumnState'
import {
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import DEFAULT_FILTERS from 'core/table/filters'
import useOpportunityTemplateCollection from 'volunteering/opportunities/hooks/useOpportunityTemplateCollection'
import Loading from 'components/Loading'
import { useOpportunityTemplateColumns } from 'volunteering/opportunities/hooks/useOpportunityTemplateColumns'
import { Grid } from '@material-ui/core'
import { ExtendedPagedTable } from 'core/table/components'
import { ContainedButton, TableMenuButton } from 'civic-champs-shared'
import { Add as AddIcon, DragHandle as DragHandleIcon } from '@material-ui/icons'
import { RouteComponentProps } from 'react-router'
import {
  OpportunityTemplate,
  OpportunityTemplatePayload,
  OpportunityTemplatePersonGroupPayload,
} from 'volunteering/opportunities/interfaces'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import AddEditOpportunityTemplatePrompt from 'Event/components/AddEditOpportunityTemplatePrompt'
import useDeleteOpportunityTemplatePrompt from 'volunteering/opportunities/hooks/useDeleteOpportunityTemplatePrompt'

const tableName = 'opportunity_templates'

export const OpportunityTemplatesComponent = ({ location, history }: RouteComponentProps) => {
  const skipReset = React.useRef<boolean>()
  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()
  const [{ opportunityTemplates, loading }, { reorder, remove, update, create }, eventListeners] =
    useOpportunityTemplateCollection(false)

  useEffect(() => {
    fetchColumnState(tableName)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const showAddEditOpportunityTemplatePrompt = useShowPrompt(AddEditOpportunityTemplatePrompt)
  const addEditOpportunityTemplate = useCallback(
    async (opportunityTemplate?: OpportunityTemplate) => {
      const { values, visibilityGroups } = (await showAddEditOpportunityTemplatePrompt({
        opportunityTemplate,
      })) as {
        values: OpportunityTemplatePayload
        visibilityGroups: Omit<OpportunityTemplatePersonGroupPayload, 'opportunityTemplateId'>[]
      }
      if (opportunityTemplate) {
        update(opportunityTemplate.id, values, visibilityGroups)
      } else {
        create(values, visibilityGroups)
      }
    },
    [create, showAddEditOpportunityTemplatePrompt, update],
  )

  const addOpportunity = useCallback(() => addEditOpportunityTemplate(), [addEditOpportunityTemplate])

  const useShowDeleteOpportunityTemplate = useDeleteOpportunityTemplatePrompt(remove)

  const columns = useOpportunityTemplateColumns({
    editOpportunityTemplate: addEditOpportunityTemplate,
    deleteOpportunityTemplate: useShowDeleteOpportunityTemplate,
  })

  const table = useTable(
    {
      initialState: {
        hiddenColumns: [],
        // @ts-ignore
        globalFilter: '',
      },
      filterTypes: DEFAULT_FILTERS,
      data: opportunityTemplates,
      // @ts-ignore
      columns,
      autoResetSortBy: !skipReset.current,
      autoResetPage: !skipReset.current,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
  )

  const onReorderRoles = useCallback(() => {
    reorder(table)
  }, [reorder, table])

  if (loading || columnStateLoading) {
    return <Loading />
  }

  return (
    <Grid container>
      <ExtendedPagedTable
        history={history}
        location={location}
        table={table}
        columns={columns}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Opportunity Templates"
        buttons={
          <TableMenuButton startIcon={<DragHandleIcon />} onClick={onReorderRoles} rightMargin>
            Reorder
          </TableMenuButton>
        }
        addButton={
          <ContainedButton startIcon={<AddIcon />} onClick={addOpportunity}>
            Add Opportunity Template
          </ContainedButton>
        }
        useGlobalSearch
        eventListeners={eventListeners}
        skipReset={skipReset}
      />
    </Grid>
  )
}

export const OpportunityTemplates = (props: RouteComponentProps) => (
  <PageHeader title="Opportunity Templates" subTitle="Create and manage templates for opportunities">
    <OpportunityTemplatesComponent {...props} />
  </PageHeader>
)

export default OpportunityTemplates
