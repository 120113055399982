import { Status } from 'civic-champs-shared/api/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { OpportunityTemplate, OpportunityTemplatePayload } from 'volunteering/opportunities/interfaces'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'

export const useCreateOpportunityTemplate = (): [
  (body: OpportunityTemplatePayload) => Promise<OpportunityTemplate>,
  Status<OpportunityTemplate>,
] => {
  const org = useCurrentOrg()
  const [request, status] = useFetchRefactored<OpportunityTemplate>()

  const createOpportunityTemplates = useCallback(
    (body: OpportunityTemplatePayload) => {
      return request({
        method: 'post',
        url: `/organizations/${org.id}/opportunity_templates`,
        config: {
          body,
        },
      })
    },
    [org.id, request],
  )

  return [createOpportunityTemplates, status]
}

export default useCreateOpportunityTemplate
