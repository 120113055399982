import { useCallback } from 'react'
import { useCreateApiNotification } from 'civic-champs-shared/api/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import useSetSortOrder from 'core/table/table-hooks/useSetSortOrder'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'

export const useReorderOpportunityTemplates = (isSchedulable: boolean, onReorder?: () => any) => {
  const org = useCurrentOrg()
  const [request] = useFetchRefactored<{ id: number }[]>({
    emptyValue: undefined,
  })

  const createNotification = useCreateApiNotification()
  const [setSortOrder] = useSetSortOrder()

  return useCallback(
    async ({ rows, sortOption }) => {
      const notification = createNotification('Reordering opportunity templates')
      try {
        await setSortOrder('opportunity_templates', `"${sortOption.field}" ${sortOption.order}`)

        if (sortOption.isCustom) {
          // @ts-ignore
          await request({
            method: 'put',
            url: `/organizations/${org.id}/opportunity_templates_order`,
            config: {
              body: rows.map(({ id }: any, index: number) => ({ id, order: index + 1 })),
              queryStringParameters: { isSchedulable },
            },
          })
        }

        await onReorder?.()

        notification.onSuccess('Opportunity templates reordered!')
      } catch (error) {
        notification.onError(`Could not reorder opportunity templates`, error)
      }
    },
    [createNotification, onReorder, isSchedulable, org.id, request, setSortOrder],
  )
}

export default useReorderOpportunityTemplates
