import { useCallback } from 'react'
import { useFetchRefactored, useCreateApiNotification } from 'civic-champs-shared/api/hooks'

export const useMergeRoles = (getRoles: () => {}) => {
  const [request] = useFetchRefactored<{ id: number }[]>({
    emptyValue: undefined,
  })

  const createNotification: any = useCreateApiNotification()

  return useCallback(
    async (roles: any[], name: string) => {
      const notification = createNotification('Merging roles')
      try {
        // @ts-ignore
        await request({
          method: 'post',
          url: `/roles/merges`,
          config: {
            body: {
              replacedRoleIds: roles.map(({ id }) => id),
              name,
            },
          },
        })

        await getRoles()

        notification.onSuccess('Roles merged!')
      } catch (error) {
        notification.onError(`Could not merge roles`, error)
      }
    },
    [createNotification, getRoles, request],
  )
}

export default useMergeRoles
