import {
  OpportunityTemplate,
  OpportunityTemplatePayload,
  OpportunityTemplatePersonGroupPayload,
  OrderBody,
} from 'volunteering/opportunities/interfaces'
import { useCreateApiNotification, useRemoteCollection } from 'civic-champs-shared/api/hooks'
import useGetOpportunityTemplates from 'volunteering/opportunities/hooks/useGetOpportunityTemplates'
import { useCallback, useEffect } from 'react'
import useUpdateOpportunityTemplate from 'volunteering/opportunities/hooks/useUpdateOpportunityTemplate'
import useCreateOpportunityTemplate from 'volunteering/opportunities/hooks/useCreateOpportunityTemplate'
import useDeleteOpportunityTemplate from 'volunteering/opportunities/hooks/useDeleteOpportunityTemplate'
import useSetOpportunityTemplateGroups from 'volunteering/opportunities/hooks/useSetOpportunityTemplateGroups'
import useReorderOpportunityTemplatesPrompt from 'volunteering/opportunities/hooks/useReorderOpportunityTemplatesPrompt'
import { CollectionEventListeners } from 'civic-champs-shared/api/hooks/useRemoteCollection'
import { useCurrentOrg, useCurrentUser } from 'civic-champs-shared/auth/hooks'

interface OpportunityTemplatesCollectionData {
  opportunityTemplates: OpportunityTemplate[]
  loading: boolean
  refresh: () => void
}
interface OpportunityTemplateCollectionOperations {
  remove: (body: OpportunityTemplate) => void
  update: (
    id: number,
    body: OpportunityTemplatePayload,
    groups: Omit<OpportunityTemplatePersonGroupPayload, 'opportunityTemplateId'>[],
  ) => void
  create: (
    body: OpportunityTemplatePayload,
    groups: Omit<OpportunityTemplatePersonGroupPayload, 'opportunityTemplateId'>[],
  ) => void
  reorder: (body: OrderBody[]) => void
}

export default function useOpportunityTemplateCollection(
  isSchedulable: boolean,
): [
  OpportunityTemplatesCollectionData,
  OpportunityTemplateCollectionOperations,
  CollectionEventListeners<OpportunityTemplate>,
] {
  const currentUser = useCurrentUser()
  const currentOrganization = useCurrentOrg()
  const [opportunityTemplates, operations, events] = useRemoteCollection<OpportunityTemplate>()
  const createNotification = useCreateApiNotification()

  const [fetchOpportunityTemplates, { loading }] = useGetOpportunityTemplates(isSchedulable)
  const [deleteOpportunityTemplate] = useDeleteOpportunityTemplate()
  const [updateOpportunityTemplate] = useUpdateOpportunityTemplate()
  const [createOpportunityTemplate] = useCreateOpportunityTemplate()
  const [setOpportunityTemplateGroups] = useSetOpportunityTemplateGroups()

  const refresh = useCallback(() => {
    fetchOpportunityTemplates().then(operations.syncCollection)
  }, [fetchOpportunityTemplates, operations.syncCollection])

  useEffect(() => {
    refresh()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const reorder = useReorderOpportunityTemplatesPrompt(isSchedulable, refresh)

  const remove = useCallback(
    async (opportunityTemplate: OpportunityTemplate) => {
      const notification = createNotification('Deleting template')
      try {
        await deleteOpportunityTemplate(opportunityTemplate)
        operations.eagerRemove(opportunityTemplate)
        notification.onSuccess('Template deleted')
      } catch (e) {
        notification.onError('Failed to delete template', e)
      }
    },
    [createNotification, deleteOpportunityTemplate, operations],
  )

  const update = useCallback(
    async (
      id: number,
      body: OpportunityTemplatePayload,
      groups: Omit<OpportunityTemplatePersonGroupPayload, 'opportunityTemplateId'>[],
    ) => {
      const notification = createNotification('Updating template')

      try {
        const template = await updateOpportunityTemplate(id, body)
        operations.eagerReplace(template)
        notification.onSuccess('Template updated')
      } catch (e) {
        notification.onError('Failed to update template', e)
      } finally {
        await setOpportunityTemplateGroups(id, groups)
      }
    },
    [createNotification, operations, setOpportunityTemplateGroups, updateOpportunityTemplate],
  )

  const create = useCallback(
    async (
      body: OpportunityTemplatePayload,
      groups: Omit<OpportunityTemplatePersonGroupPayload, 'opportunityTemplateId'>[],
    ) => {
      const notification = createNotification('Creating template')
      let template: OpportunityTemplate | undefined
      try {
        template = await createOpportunityTemplate(body)
        operations.eagerAdd(template)
        notification.onSuccess('Template created')
      } catch (e) {
        notification.onError('Failed to create template', e)
      } finally {
        template && (await setOpportunityTemplateGroups(template.id, groups))
      }
    },
    [createNotification, createOpportunityTemplate, operations, setOpportunityTemplateGroups],
  )

  return [{ opportunityTemplates, loading, refresh }, { reorder, remove, update, create }, events]
}
