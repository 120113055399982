import { useCurrentOrg } from 'auth/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import { OrganizationLocation } from 'locations/types'

export const useFetchOpportunityLocation = () => {
  const organization = useCurrentOrg()
  const [request, { loading, error }] = useFetchRefactored<OrganizationLocation>()

  const fetchLocation = useCallback(
    async (id: number) =>
      request({
        url: `/organizations/${organization.id}/opportunity-locations/${id}`,
      }),
    [request, organization.id],
  )

  return { fetchLocation, loading, error }
}
