import {
  Row,
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { useConditionalSelectColumn } from 'core/table/table-hooks/useSelectColumn'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useChampionColumns, { useStyles } from 'champion/hooks/useChampionColumns'
import { useVolunteersCollection } from 'volunteering/volunteers/hooks'
import { useDateRangeFilter } from 'filtering/hooks'
import Grid from '@material-ui/core/Grid'
import { NATIONAL_AVG_VOLUNTEER_VALUE_PER_HOUR } from 'champion/components/ChampionsSummaryCards'
import format from 'civic-champs-shared/utils/format'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined'
import useMessageRecipientsPrompt from 'messages/hooks/useMessageRecipientsPrompt'
import Loading from 'components/Loading'
import { useFiltersFromQuery } from 'core/table/components/NewFiltersModal'
import { RouteComponentProps } from 'react-router'
import DEFAULT_FILTERS, { useGetGlobalFilter } from 'core/table/filters'
import { TableMenuButton } from 'civic-champs-shared/core/TableMenuButton'
import ExportChampionsButton from 'champion/components/ExportChampionsButton'
import { useHasRole } from 'auth/hooks'
import { INTERNAL_ADMIN, SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import { ExtendedPagedTable } from 'core/table/components/ExtendedPagedTable'
import AddIcon from '@material-ui/icons/Add'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import useGetColumnState from 'core/table/table-hooks/useGetColumnState'
import TablePopoverButton from 'civic-champs-shared/core/TablePopoverButton'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { SendNotificationModal } from 'champion/components/SendNotificationModal'
import useSendNotification from 'champion/hooks/useSendNotification'
import first from 'lodash/first'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'
import CUSTOM_FILTERS from 'champion/utils/filters'
import {
  ARRAY_OPERATOR_OPTIONS,
  NUMBER_OPERATOR_OPTIONS,
  operatorTypes,
  STRING_OPERATOR_OPTIONS,
} from 'core/table/interfaces/Filters'
import { useFeatureEnabled } from 'core/feature/hooks'
import PageHeader from 'App/components/PageHeader'
import { useGetSearchableContactsFromVolunteersArray } from 'messages/hooks/useGetSearchableContactsFromVolunteersArray'
import { ReactComponent as VerifiedIcon } from 'images/verified.svg'
import { ReactComponent as ExportIcon } from 'images/export.svg'
import { useHandleChat } from 'chats'
import useGetChampionSummary from 'champion/utils/useGetChampionsSummary'
import { Volunteer } from 'champion/utils/interface'
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import { useAddRemoveTagsPrompt } from '../hooks/useAddRemoveTagsPrompt'
import { Person } from '../../people/interface'
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined'
import useMultiGroupAddMembersPrompt from 'group/hooks/useMultiGroupAddMembersPrompt'
import usePushChampions from '../hooks/usePushChampions'
import { IntegrationKey, IntegrationProvider } from '../../integrations/types'
import useGetIntegrationKey, { emptyValue } from '../../integrations/hooks/useGetIntegrationKey'
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined'
import ImportVolunteersPrompt from '../components/ImportVolunteersPrompt'

const cards = [
  {
    header: 'Champions',
    accessor: 'count',
    transform: format.number,
  },
  {
    header: 'Hours',
    accessor: 'totalHours',
    transform: format.number,
  },
  {
    header: 'Donations',
    accessor: 'totalDonations',
    transform: format.money,
  },
  {
    header: 'Estimated Impact',
    accessor: 'estimatedImpact',
    transform: format.money,
    tooltip: `This value was calculated using the national average of $${NATIONAL_AVG_VOLUNTEER_VALUE_PER_HOUR} / hour for volunteers.`,
  },
]

const getOperatorOptions = (column: string) => {
  switch (column) {
    case 'firstName':
    case 'lastName':
    case 'email':
    case 'mobile':
    case 'name':
    case 'homePhoneNumber':
      return STRING_OPERATOR_OPTIONS

    case 'hours':
    case 'donations':
      return NUMBER_OPERATOR_OPTIONS
    case 'groups':
    case 'tags':
      return ARRAY_OPERATOR_OPTIONS
    case 'status':
      return [
        { value: operatorTypes.ANY_OF, displayValue: 'Includes' },
        { value: operatorTypes.NOT_ANY_OF, displayValue: 'Does not include' },
      ]
    default:
      return []
  }
}

const tableName = 'champions'

export const Champions = (props: RouteComponentProps) => {
  return (
    <PageHeader title="Champions" subTitle="Add and manage your organization’s volunteers">
      <ChampionsComponent {...props} />
    </PageHeader>
  )
}

const ChampionsComponent = ({ location, history }: RouteComponentProps) => {
  const skipReset = useRef<boolean>()
  const isSuperAdmin = useHasRole(SUPER_ADMIN)
  const isInternalAdmin = useHasRole(INTERNAL_ADMIN)
  const [dateRange] = useDateRangeFilter()
  const showMessageRecipientsPrompt = useMessageRecipientsPrompt()
  const { filters } = useFiltersFromQuery(location.search, getOperatorOptions)
  const [includeInactive, setIncludeInactive] = useState(true)
  const [
    { volunteers, initiallyLoaded },
    { addVolunteerLegacy: addVolunteer, removeVolunteer, handleTagsChange, handleGroupsAdded },
    eventListeners,
  ] = useVolunteersCollection(dateRange, includeInactive) as any
  const columns = useChampionColumns()
  const columnStyles = useStyles()
  const getSearchableContacts = useGetSearchableContactsFromVolunteersArray(volunteers)
  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()
  const showNotificationsModal = useShowPrompt(SendNotificationModal)
  const organization = useCurrentOrg()
  const championsTagsEnabled = useFeatureEnabled('ChampionsTags')
  const chatsEnabled = useFeatureEnabled('Chats')
  const getSummary = useGetChampionSummary(NATIONAL_AVG_VOLUNTEER_VALUE_PER_HOUR)
  const showTagsPrompt = useAddRemoveTagsPrompt(handleTagsChange)
  const isLglIntegrationEnabled = useFeatureEnabled('LglIntegration')
  const lglChampionsSyncEnabled = useFeatureEnabled('ChampionsLGLSync')
  const bulkAddMembersEnabled = useFeatureEnabled('BulkAddMembers')
  const newVolunteerImportEnabled = useFeatureEnabled('NewVolunteerImport')
  const newVolunteerImportForSuperAdminEnabled = useFeatureEnabled('NewVolunteerImportForSuperAdmin')
  const showImportButton =
    newVolunteerImportEnabled && (isInternalAdmin || (newVolunteerImportForSuperAdminEnabled && isSuperAdmin))
  const pushChampions = usePushChampions()
  const showImportVolunteerPrompt = useShowPrompt(ImportVolunteersPrompt)

  const [integrationKey, setIntegrationKey] = useState<IntegrationKey>(emptyValue)
  const fetchIntegrationKey = useGetIntegrationKey()
  useEffect(() => {
    fetchIntegrationKey(IntegrationProvider.LGL).then(setIntegrationKey)
  }, [fetchIntegrationKey])

  const globalFilter = useGetGlobalFilter({
    columns,
    filterTypes: { ...DEFAULT_FILTERS, ...CUSTOM_FILTERS },
    getOperatorOptions,
  })
  const [sendNotification, { loading: sendNotificationLoading }] = useSendNotification(organization.id)
  const handleResendVerification = useCallback(
    (rows: any[]) => {
      const data = rows.map(({ original }) => original)
      const {
        user: { id, email, phoneNumber },
      } = first(data)
      if (rows.length > 1 || (!email && !phoneNumber)) {
        showNotificationsModal({ persons: data })
      } else {
        sendNotification([{ personId: id, sendMethod: email ? 'email' : 'phone' }])
      }
    },
    [sendNotification, showNotificationsModal],
  )

  useEffect(() => {
    fetchColumnState(tableName)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const table = useTable(
    {
      initialState: {
        hiddenColumns: [
          'firstName',
          'lastName',
          'email',
          'mobile',
          'homePhoneNumber',
          'address',
          'consolidatedContact',
          'status',
          'startedAt',
        ],
        // @ts-ignore
        globalFilter: '',
        filters,
      },
      globalFilter,
      // @ts-ignore
      filterTypes: { ...DEFAULT_FILTERS, ...CUSTOM_FILTERS },
      data: volunteers,
      columns,
      autoResetSortBy: !skipReset.current,
      autoResetPage: !skipReset.current,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnStyles.selectColumn),
  )

  const [selectedRows, selectedRowsCount, selectedUnverifiedRows, selectedUnverifiedRowsCount] = useMemo(() => {
    const selectedUnverifiedRows = table.selectedFlatRows.filter(
      ({
        original: {
          user: { emailVerified, phoneNumberVerified },
        },
      }: any) => !emailVerified && !phoneNumberVerified,
    )
    return [
      table.selectedFlatRows,
      table.selectedFlatRows.length,
      selectedUnverifiedRows,
      selectedUnverifiedRows.length,
    ]
  }, [table.selectedFlatRows])

  const handlePushChampions = useCallback(async () => {
    await pushChampions({
      provider: IntegrationProvider.LGL,
      personIds: selectedRows.map((row: Row<Volunteer>) => row.original.user.id),
    })
  }, [pushChampions, selectedRows])

  const onMessageVolunteers = () => {
    const recipientPersonIds = selectedRows.map((row: any) => row.original.user.id)
    showMessageRecipientsPrompt({
      recipientPersonIds,
      getSearchableContacts,
      autocompleteSectionTitle: 'All Volunteers',
    })
  }

  const onRemoveVolunteer = async () => {
    await removeVolunteer(selectedRows.map((row: any) => row.original))
    table.toggleAllRowsSelected(false)
  }

  const onUpdateTags = useCallback(() => {
    showTagsPrompt(selectedRows.map((row: any) => row.original.user as Person))
  }, [selectedRows, showTagsPrompt])

  const [handleChat, processing] = useHandleChat({ selectedRows, history })

  const showAddMembersPrompt = useMultiGroupAddMembersPrompt(handleGroupsAdded)
  const onBulkAddMembers = useCallback(async () => {
    const selectedPersons = selectedRows.map((row: any) => row.original.user as Person)
    await showAddMembersPrompt(selectedPersons)
  }, [selectedRows, showAddMembersPrompt])

  const booleanFilters = useMemo(
    () => [{ name: 'Show All Champions', value: includeInactive, onChange: setIncludeInactive }],
    [includeInactive],
  )

  if (!initiallyLoaded || columnStateLoading || processing) {
    return <Loading />
  }

  return (
    <Grid container>
      <ExtendedPagedTable<Volunteer>
        getSummary={getSummary}
        cards={cards}
        history={history}
        location={location}
        table={table}
        columns={columns}
        getOperatorOptions={getOperatorOptions}
        binaryFilters={booleanFilters}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Champions"
        filterSubHeader="Select volunteer attributes to filter by:"
        buttons={
          <>
            <TableMenuButton
              startIcon={<MailOutlineIcon />}
              onClick={onMessageVolunteers}
              disabled={!selectedRowsCount}
              rightMargin
            >
              Message
            </TableMenuButton>
            <ExportChampionsButton data={selectedRows} disabled={!selectedRowsCount} includeDonations={isSuperAdmin} />
          </>
        }
        popover={
          <>
            <TablePopoverButton
              startIcon={<VerifiedIcon style={{ width: '20px', height: '20px' }} />}
              disabled={!selectedUnverifiedRowsCount || sendNotificationLoading}
              onClick={() => handleResendVerification(selectedUnverifiedRows)}
            >
              Resend Verification Link
            </TablePopoverButton>

            {chatsEnabled && (
              <TablePopoverButton
                startIcon={<MessageOutlinedIcon />}
                disabled={!selectedRowsCount}
                onClick={handleChat}
              >
                Add to Chat
              </TablePopoverButton>
            )}
            {bulkAddMembersEnabled && (
              <TablePopoverButton
                startIcon={<GroupOutlinedIcon />}
                disabled={!selectedRowsCount}
                onClick={onBulkAddMembers}
              >
                Add to Group
              </TablePopoverButton>
            )}
            {championsTagsEnabled && (
              <TablePopoverButton
                startIcon={<LabelOutlinedIcon />}
                onClick={onUpdateTags}
                disabled={!selectedRowsCount}
              >
                Add/Remove Tags
              </TablePopoverButton>
            )}
            {isLglIntegrationEnabled && lglChampionsSyncEnabled && integrationKey.enabled && !!integrationKey.token && (
              <TablePopoverButton
                startIcon={<ExportIcon />}
                disabled={!selectedRowsCount}
                onClick={handlePushChampions}
              >
                Export to LGL
              </TablePopoverButton>
            )}
            <TablePopoverButton
              startIcon={<RemoveCircleOutlineOutlinedIcon />}
              disabled={!selectedRowsCount}
              onClick={onRemoveVolunteer}
            >
              Remove Champion{selectedRowsCount > 1 ? 's' : ''}
            </TablePopoverButton>
            {showImportButton && (
              <TablePopoverButton startIcon={<SaveAltOutlinedIcon />} onClick={showImportVolunteerPrompt}>
                Import Volunteers
              </TablePopoverButton>
            )}
          </>
        }
        addButton={
          <ContainedButton startIcon={<AddIcon />} onClick={addVolunteer}>
            Add Champion
          </ContainedButton>
        }
        useGlobalSearch
        useFilters
        useDateRange
        eventListeners={eventListeners}
        skipReset={skipReset}
      />
    </Grid>
  )
}
