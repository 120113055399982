import { Status } from 'civic-champs-shared/api/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import {
  OpportunityTemplatePersonGroup,
  OpportunityTemplatePersonGroupPayload,
} from 'volunteering/opportunities/interfaces'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'

export const useSetOpportunityTemplateGroups = (): [
  (
    opportunityId: number,
    body: Omit<OpportunityTemplatePersonGroupPayload, 'opportunityTemplateId'>[],
  ) => Promise<OpportunityTemplatePersonGroup[]>,
  Status<OpportunityTemplatePersonGroup[]>,
] => {
  const org = useCurrentOrg()
  const [request, status] = useFetchRefactored<OpportunityTemplatePersonGroup[]>({
    errorMessage: 'Error Setting Template Groups',
    emptyValue: [],
  })

  const setOpportunityTemplateGroups = useCallback(
    (opportunityId: number, body: Omit<OpportunityTemplatePersonGroupPayload, 'opportunityTemplateId'>[]) => {
      return request({
        method: 'put',
        url: `/organizations/${org.id}/opportunity_templates/${opportunityId}/groups`,
        config: {
          body,
        },
      })
    },
    [org.id, request],
  )

  return [setOpportunityTemplateGroups, status]
}

export default useSetOpportunityTemplateGroups
