import { DEFAULT_GEOFENCING } from '../../civic-champs-shared/constants/GEO_DATA'
import moment from 'moment-timezone'
import { OpportunityVisibility } from '../interfaces'
import yup from '../../civic-champs-shared/utils/yup'
import { Address } from '../../civic-champs-shared/formik/components/AddressField'
import { EventGroup } from '../interfaces/interfaceCreateEditEvent'

export interface FormData {
  name: string
  description: string
  instructions: string
  address: Address
  locationIsAddress: 'true' | 'false'
  locationDetails: string
  startDate: moment.Moment
  endDate: moment.Moment | null
  startTime: moment.Moment
  endTime: moment.Moment | null
  trackSignups: 'true' | 'false'
  isOffer: true
  visibility: OpportunityVisibility
  visibilityGroups: EventGroup[]
}

export const offerInitialValues = {
  name: '',
  description: '',
  instructions: '',
  address: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    zip: '',
    geofencing: DEFAULT_GEOFENCING,
    country: '',
    county: '',
    utcOffset: 0,
  },
  locationIsAddress: 'true',
  locationDetails: '',
  startDate: moment(),
  startTime: moment().set('hour', 12).set('minute', 0).set('second', 0),
  endDate: null,
  endTime: null,
  trackSignups: 'false',
  isOffer: true,
  visibility: OpportunityVisibility.PUBLIC,
  visibilityGroups: [],
} as FormData

export const validationSchema = yup.object({
  name: yup.string().required(),
  description: yup.string().max(10000).notRequired(),
  instructions: yup.string().max(10000).notRequired(),
  address: yup.object().when('locationIsAddress', (locationIsAddress: 'true' | 'false', schema: any) =>
    locationIsAddress === 'true'
      ? schema.shape({
          line1: yup.string().notRequired(),
          description: yup.string().required(),
          line2: yup.string().notRequired(),
          city: yup.string().notRequired(),
          state: yup.string().notRequired(),
          zip: yup.string().notRequired(),
          geofencing: yup.object().required(),
          country: yup.string().notRequired(),
          county: yup.string().notRequired(),
          utcOffset: yup.number().notRequired(),
        })
      : schema.notRequired(),
  ),
  locationDetails: yup
    .string()
    .notRequired()
    .when('locationIsAddress', (locationIsAddress: 'true' | 'false', schema: any) =>
      locationIsAddress === 'true' ? schema.nullable() : schema,
    ),
  startDate: yup.date().required(),
  startTime: yup.date().required(),
  endTime: yup.date().when('endDate', (endDate: moment.Moment | null, schema: any) => {
    return endDate === null ? schema.nullable() : schema.required('End time is required when end date is set.')
  }),
  endDate: yup.date().nullable(),
  trackSignups: yup.boolean().required(),
  visibility: yup
    .string()
    .oneOf(Object.values(OpportunityVisibility).filter(option => option !== OpportunityVisibility.PRIVATE))
    .default(OpportunityVisibility.PUBLIC),
})
