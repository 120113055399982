import { useCallback, useEffect, useState } from 'react'

import { useFetch, useRemoteCollection } from 'civic-champs-shared/api/hooks'
import format from 'civic-champs-shared/utils/format'

import { useCurrentOrg } from 'auth/hooks'
import { useAddActivityPrompt, useEditActivityPrompt, useRemoveActivitiesPrompt } from './index'
import { useFeatureEnabled } from '../../../core/feature/hooks'

export const useActivitiesCollection = filters => {
  const [request, { loading, error }] = useFetch()
  const [activities, operations, events] = useRemoteCollection()
  const isVolunteerTagsEnabled = useFeatureEnabled('ActivityVolunteerTags')
  const organization = useCurrentOrg()
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)
  const { syncCollection, eagerAdd, eagerReplace, eagerRemoveMany } = operations

  const getActivities = useCallback(
    async filters => {
      const { startDate, endDate, opportunityId, mapOccurrencesApproximately } = filters
      const params = {}
      if (mapOccurrencesApproximately) {
        params.mapOccurrencesApproximately = true
      }

      if (startDate) {
        params.from = format.dateForUrl(startDate)
      }

      if (endDate) {
        params.to = format.dateForUrl(endDate)
      }

      if (isVolunteerTagsEnabled) {
        params.includeVolunteerTags = true
      }

      const updatedActivities = await request({
        url: opportunityId
          ? `/opportunities/${opportunityId}/activities`
          : `/organizations/${organization.id}/activities`,
        config: {
          queryStringParameters: params,
        },
      })

      syncCollection(updatedActivities)
      setInitiallyLoaded(true)
    },
    [isVolunteerTagsEnabled, organization.id, request, syncCollection],
  )
  useEffect(() => {
    getActivities(filters)
  }, [filters, getActivities])

  const addActivity = useAddActivityPrompt(eagerAdd)
  const editActivity = useEditActivityPrompt(eagerReplace)
  const removeActivities = useRemoveActivitiesPrompt(eagerRemoveMany)

  return [
    {
      activities,
      loading,
      error,
      initiallyLoaded,
    },
    {
      addActivity,
      editActivity,
      removeActivities,
    },
    events,
  ]
}

export default useActivitiesCollection
