import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { ConfirmDialog } from 'civic-champs-shared/core/confirm-dialog/'
import { OpportunityTemplate } from 'volunteering/opportunities/interfaces'

export const useDeleteOpportunityTemplatePrompt = (remove: (opportunityTemplate: OpportunityTemplate) => void) => {
  const showPrompt = useShowPrompt(ConfirmDialog)

  return useCallback(
    async (opportunityTemplate: OpportunityTemplate) => {
      const text = `Are you sure you want to delete "${opportunityTemplate.name}"? The action can not be undone.`
      const confirmed = await showPrompt({
        text,
        title: 'Delete Opportunity Template',
        confirmText: 'Delete',
        cancelText: 'Cancel',
        type: 'warning',
      })

      if (confirmed) {
        await remove(opportunityTemplate)
      }
    },
    [showPrompt, remove],
  )
}

export default useDeleteOpportunityTemplatePrompt
