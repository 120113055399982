import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ChampionProfile, PreferredContactMethod } from 'champion/interfaces'
import format from 'civic-champs-shared/utils/format'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import EditIcon from '@material-ui/icons/Edit'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import { Address } from 'civic-champs-shared/core/location/utils'
import LocalHospitalIcon from '@material-ui/icons/LocalHospital'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import ProfileEdit from 'champion/components/ProfileEdit'
import useUpdatePerson from 'people/hooks/useUpdatePerson'
import { ReactComponent as VerifiedIcon } from 'images/verified.svg'
import useProfileInfoCardStyles from 'champion/hooks/useProfileInfoCardStyles'
import WaiverChip from 'champion/components/WaiverChip'
import useMessageRecipientsPrompt from 'messages/hooks/useMessageRecipientsPrompt'
import useFetchPersonSuggestions from 'civic-champs-shared/api/hooks/useFetchPersonSuggestions'
import { MessagingContact } from 'messages/types'
import moment from 'moment'
import { SendMethod } from 'civic-champs-shared/question-sets/types/invitation'
import { OutlinedButton } from 'civic-champs-shared'
import useSendNotification from 'champion/hooks/useSendNotification'
import AvatarPrompt from 'champion/components/AvatarPrompt'
import cn from 'classnames'
import { MenuItem, Select } from '@material-ui/core'
import { useVolunteerStatus } from 'champion/hooks/useVolunteerStatus'
import map from 'lodash/map'
import Tag from 'components/tag/Tag'

const addressToString = (address?: Address): string => {
  if (!address) return ''
  const arr = []
  for (const key of ['line1', 'line2', 'city', 'state', 'zipCode', 'zip']) {
    // @ts-ignore
    if (address[key]) arr.push(address[key])
  }
  return arr.join(', ')
}

const getPreferredContact = (contact?: PreferredContactMethod): string => {
  if (contact?.sendMethod === SendMethod.DO_NOT_CONTACT) return 'Do Not Contact'
  return contact?.contact || 'N/A'
}

export const ProfileInfoCard = ({ profile, getProfile }: { profile: ChampionProfile; getProfile: any }) => {
  const { updatePerson } = useUpdatePerson(() => getProfile(profile.user.id))
  const [searchableContacts, setSearchableContacts] = React.useState<MessagingContact[]>([])
  const [sendNotification] = useSendNotification(profile.volunteer.organization.id)
  const showEditProfile = useShowPrompt(ProfileEdit)
  const { user, volunteer } = profile
  const { status, updateStatus } = useVolunteerStatus(volunteer.status as string, user.id)
  const showMessageRecipientsPrompt = useMessageRecipientsPrompt()
  const [url, setUrl] = useState(user.avatar?.url)
  const classes = useProfileInfoCardStyles()
  const [fetchSuggestions] = useFetchPersonSuggestions()
  const showAvatarPrompt = useShowPrompt(AvatarPrompt)
  const handleAvatarChange = useCallback(async () => {
    const avatar = await showAvatarPrompt({ personId: profile.user.id })
    setUrl(avatar.url)
  }, [profile.user.id, showAvatarPrompt])

  useEffect(() => {
    fetchSuggestions('').then(({ suggestions }) =>
      setSearchableContacts(
        suggestions.map(({ id, givenName, familyName, contact }) => ({
          id,
          firstName: givenName,
          lastName: familyName,
          ...(contact.includes('@') ? { email: contact } : { phoneNumber: contact }),
        })),
      ),
    )
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const resendMethod: SendMethod | null = useMemo(() => {
    if (user.emailVerified || user.phoneNumberVerified || (!user.email && !user.phoneNumber)) return null
    return user.email ? SendMethod.EMAIL : SendMethod.PHONE
  }, [user])

  const onResend = useCallback(() => {
    sendNotification([{ personId: user.id, sendMethod: resendMethod }])
  }, [resendMethod, sendNotification, user.id])

  const onMessage = useCallback(() => {
    showMessageRecipientsPrompt({
      recipientPersonIds: [user.id],
      getSearchableContacts: () => Promise.resolve(searchableContacts),
      autocompleteSectionTitle: 'All Volunteers',
    })
  }, [searchableContacts, showMessageRecipientsPrompt, user.id])

  const doNotContact = user.preferredContact?.sendMethod === SendMethod.DO_NOT_CONTACT

  return (
    <div className={classes.wrapperPadding}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <div className={classes.avatar} style={url ? { backgroundImage: `url(${url})` } : {}}>
            {!url && user.givenName[0] + user.familyName[0]}
            <div className={classes.avatarEdit} onClick={handleAvatarChange}>
              <EditIcon />
            </div>
          </div>
          <div className={classes.info}>
            <p className={classes.title}>
              {user.givenName} {user.familyName}
            </p>
            <div className={classes.outlinedSelectContainer}>
              <Select
                value={status}
                autoWidth
                classes={{ outlined: classes.outlinedSelect }}
                variant="outlined"
                onChange={updateStatus}
              >
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="archived">Archived</MenuItem>
                {status === 'inactive' && <MenuItem value="inactive">Inactive</MenuItem>}
              </Select>
            </div>
            <p>Member Since: {format.date(volunteer.volunteering.startedAt)}</p>
            <p>Preferred Contact: {getPreferredContact(user.preferredContact) || 'N/A'}</p>
          </div>
        </div>
        <div className={classes.padded}>
          <div className={classes.buttons}>
            <div>
              <ContainedButton
                startIcon={<MailOutlineIcon />}
                disabled={!user.email && !user.phoneNumber}
                onClick={onMessage}
              >
                Message
              </ContainedButton>
            </div>
            <div>
              <ContainedButton
                startIcon={<EditIcon />}
                // @ts-ignore
                onClick={() => showEditProfile({ profile }).then(updatePerson)}
              >
                Edit Profile
              </ContainedButton>
            </div>
          </div>
          <table className={classes.table}>
            <tbody>
              {user.email && (
                <tr>
                  <td>Email:</td>
                  <td className={cn({ [classes.doNotContact]: doNotContact })}>
                    {user.email}{' '}
                    {user.emailVerified && (
                      <VerifiedIcon className={cn(classes.verified, { [classes.doNotContact]: doNotContact })} />
                    )}
                  </td>
                </tr>
              )}
              {user.phoneNumber && (
                <tr>
                  <td>Mobile:</td>
                  <td className={cn({ [classes.doNotContact]: doNotContact })}>
                    {format.phoneNumber(user.phoneNumber)}{' '}
                    {user.phoneNumberVerified && (
                      <VerifiedIcon className={cn(classes.verified, { [classes.doNotContact]: doNotContact })} />
                    )}
                  </td>
                </tr>
              )}
              {user.homePhoneNumber && (
                <tr>
                  <td>Home Phone:</td>
                  <td>{format.phoneNumber(user.homePhoneNumber)}</td>
                </tr>
              )}
              {resendMethod && (
                <tr>
                  <td colSpan={2}>
                    <div className={classes.resendWrapper}>
                      <OutlinedButton
                        startIcon={<MailOutlineIcon />}
                        classes={{ startIcon: classes.resendIcon }}
                        className={classes.resend}
                        onClick={onResend}
                      >
                        Resend Verification Link
                      </OutlinedButton>
                    </div>
                  </td>
                </tr>
              )}
              {user.address && (
                <tr>
                  <td>Address:</td>
                  <td>{addressToString(user.address)}</td>
                </tr>
              )}
              {user.birthDate && (
                <tr>
                  <td>Age:</td>
                  <td>{moment().diff(moment(user.birthDate), 'years')}</td>
                </tr>
              )}
            </tbody>
          </table>
          <div className={classes.spoiler} />

          <h4 className={classes.subtitle}>
            <LocalHospitalIcon style={{ color: '#BA1B1B', position: 'relative', top: '6px' }} />
            Emergency contact
          </h4>
          {user.emergencyContact && (
            <table className={classes.table}>
              <tbody>
                {user.emergencyContact.givenName && (
                  <tr>
                    <td>First Name:</td>
                    <td>{user.emergencyContact.givenName}</td>
                  </tr>
                )}
                {user.emergencyContact.givenName && (
                  <tr>
                    <td>Last Name:</td>
                    <td>{user.emergencyContact.familyName}</td>
                  </tr>
                )}
                {user.emergencyContactRelationship && (
                  <tr>
                    <td>Relationship:</td>
                    <td>{user.emergencyContactRelationship}</td>
                  </tr>
                )}
                {user.emergencyContact.phoneNumber && (
                  <tr>
                    <td>Mobile:</td>
                    <td>{format.phoneNumber(user.emergencyContact.phoneNumber)}</td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
          <div className={classes.spoiler} />

          <h4 className={classes.subSubTitle}>Groups</h4>
          <div className={classes.groups}>{map(profile.groups, 'name').join(', ')}</div>

          <div className={classes.spoiler} />

          <h4 className={classes.subSubTitle}>Waivers</h4>
          <div className={classes.waivers}>
            {profile.credentials
              .filter(({ userCredential }) => !!userCredential)
              .map((credential, id) => (
                <WaiverChip key={id} credential={credential} />
              ))}
          </div>

          <div className={classes.spoiler} />

          <h4 className={classes.subSubTitle}>Tags</h4>
          <div className={classes.waivers}>
            {(profile.tags || []).map(({ id, name, color }) => (
              <Tag key={id} id={id} label={name} color={color} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
export default ProfileInfoCard
