import { QuestionSet, QuestionSetResponse } from 'civic-champs-shared/question-sets/types'
import { Person } from 'people/interface'
import { Survey } from 'surveys/types'

export type MentorshipProgramStatus = 'draft' | 'active'

export interface CurrentOrganizationProps {
  id: number
  name: string
}

export interface MentorshipStatistics {
  programCount: number
  matchCount: number
  meetingCount: number
  momentCount: number
  averageMeetingTime: number
  positiveFeedbackPercent: number
  negativeFeedbackPercent: number
}

export interface MentorshipProgram {
  id: number
  organizationId: number
  name: string
  startsAt: Date
  endsAt: Date
  description: string
  mentorSurveyId: number
  menteeSurveyId: number
  status: MentorshipProgramStatus
  mentorSurvey?: Survey
  menteeSurvey?: Survey
  organization?: Organization
}

export interface MentorshipProgramWithStatistics extends MentorshipProgram {
  matchCount: number
  meetingLengthAverage: number | null
  mentorFeedbackAverage: number | null
  menteeFeedbackAverage: number | null
}

export interface Tag {
  id: number
  organizationId: number
  name: string
  color: string
  matchId?: number
}

export interface ProgramMatch {
  id: number
  programId: number
  mentorId: number
  menteeId: number
  startedAt: Date
  stoppedAt: Date
  meetingsTimezone: string
  organizationId: number
  mentee?: Person
  mentor?: Person
  program?: MentorshipProgram
  tags?: Tag[]
  meetingCount: number
  momentCount: number
  averageMeetingDuration: number
  positiveFeedbackCount: number
  negativeFeedbackCount: number
}

export enum ProgramMeetingType {
  meeting = 'meeting',
  moment = 'moment',
}
export enum MentoringFeedbackType {
  mentor = 'mentor',
  mentee = 'mentee',
}
export interface MentoringFeedback {
  id: number
  type: MentoringFeedbackType
  meetingId: number
  notes: string
  rating: boolean
  startTime: Date
  endTime: Date
  duration: number
  answerSetId: number
  program?: MentorshipProgram
  answersSet?: QuestionSetResponse
}

export interface ProgramMeeting {
  id: number
  location: string
  type: ProgramMeetingType
  title: string
  goals: string
  startsAt: Date
  endsAt: Date
  matchId: number
  createdById: number
  feedback?: MentoringFeedback[]
  match?: ProgramMatch
  mentorQuestionSet?: QuestionSet
  menteeQuestionSet?: QuestionSet
  _links?: {
    menteeFeedback: string
    mentorFeedback: string
  }
}

export interface ProgramMeetingForGrid {
  id: number
  location: string
  type: ProgramMeetingType
  title: string
  goals: string
  startsAt: Date
  endsAt: Date
  matchId: number
  createdById: number
  mentorName: string
  menteeName: string
  programName: string
  mentorRating?: boolean
  menteeRating?: boolean
  answers?: {
    [key: number]: string | number | string[]
  }
}

export interface MentoringFeedbackColumn {
  id: number
  value: string
}

export interface ProgramMeetingQuestionColumns {
  [MentoringFeedbackType.mentor]?: MentoringFeedbackColumn[]
  [MentoringFeedbackType.mentee]?: MentoringFeedbackColumn[]
}

export interface ProgramMeetingGridData {
  meetings: ProgramMeetingForGrid[]
  questionColumns: ProgramMeetingQuestionColumns
}

export interface Organization {
  id: number
  name: string
  website: string
  address?: OrganizationAddress
  location: GeoPoint
  timeZone: string
  createdAt: Date
  deletedAt?: Date
  isTest: boolean
  totalMembers?: number
  totalVolunteers?: number
  totalActivities?: number
  totalHoursVolunteered?: number
  description?: string
  logoUrl?: string
  smsCredits: number
  emailCredits: number
}

export interface OrganizationAddress {
  line1?: string
  line2?: string
  city?: string
  state?: string
  zip?: string
  country?: string
}

export interface GeoPoint {
  type: 'Point'
  coordinates: [number, number]
}
