import { Chat, ChatApiResponse } from 'chats/interfaces'
import { DeleteChatsResult } from 'chats/hooks'
import reduce from 'lodash/reduce'

export const mapChat = ({ createdAt, lastActivityAt, ...rest }: ChatApiResponse): Chat => ({
  ...rest,
  createdAt: new Date(createdAt),
  lastActivityAt: new Date(lastActivityAt),
})

export const isAllResponsesSuccessful = (result: DeleteChatsResult) =>
  reduce(Object.values(result), (acc, val) => acc && val, true)
