import React, { useCallback, useEffect, useMemo } from 'react'
import { OpportunityVisibility } from 'Event/interfaces'
import { FormModal } from 'components/FormModal'
import { DEFAULT_GEOFENCING } from 'civic-champs-shared/constants/GEO_DATA'
import { EventOverviewScreen, EventOverviewScreenValues } from 'Event/components/EventOverviewScreen'
import { useGroups } from 'group/hooks/useGroups'
import { Group } from 'Event/components/opportunity/GroupPicker/types'
import { EventGeofencing, mapEventGeofencingToGeofencing, mapGeofencingToEventGeofencing } from 'utils/event'
import { useEnabledGroups } from 'Event/components/opportunity/GroupPicker/hooks'
import { EventGroup } from 'Event/interfaces/interfaceCreateEditEvent'
import Loading from 'civic-champs-shared/core/Loading'
import {
  OpportunityTemplate,
  OpportunityTemplatePayload,
  OpportunityTemplatePersonGroupPayload,
} from 'volunteering/opportunities/interfaces'
import { useGetOpportunityTemplateGroups } from 'volunteering/opportunities/hooks/useGetOpportunityTemplateGroups'

interface Props {
  showing: boolean
  complete: (result: {
    values: OpportunityTemplatePayload
    visibilityGroups: Omit<OpportunityTemplatePersonGroupPayload, 'opportunityTemplateId'>[]
  }) => void
  close: () => void
  opportunityTemplate?: OpportunityTemplate
}

const defaultValues: EventOverviewScreenValues = {
  name: '',
  description: '',
  isActive: true,
  geofencing: DEFAULT_GEOFENCING,
  address: '',
  city: '',
  state: '',
  zip: '',
  visibility: OpportunityVisibility.PUBLIC,
  visibilityGroups: [],
  locationDetails: '',
  locationIsAddress: 'true',
}

const mapOpportunityTemplateToValues = (
  opportunityTemplate: OpportunityTemplate,
  visibilityGroups: EventGroup[],
): EventOverviewScreenValues => ({
  name: opportunityTemplate.name,
  description: '',
  isActive: opportunityTemplate.isActive,
  geofencing: mapEventGeofencingToGeofencing(opportunityTemplate.geofencing as EventGeofencing),
  address: opportunityTemplate.streetAddress || '',
  city: opportunityTemplate.city || '',
  state: opportunityTemplate.state || '',
  zip: opportunityTemplate.zip || '',
  visibility: opportunityTemplate.visibility,
  visibilityGroups,
  locationDetails: opportunityTemplate.locationDetails || '',
  locationIsAddress: opportunityTemplate.locationIsAddress ? 'true' : 'false',
})

const mapValuesToOpportunityTemplate = (
  values: EventOverviewScreenValues,
  id?: number,
): OpportunityTemplatePayload => ({
  ...(id ? { id } : {}),
  name: values.name,
  schedulable: false,
  isActive: values.isActive || false,
  streetAddress: values.address,
  geofencing: mapGeofencingToEventGeofencing(values.geofencing),
  city: values.city,
  state: values.state,
  zip: values.zip,
  isPrivate: values.visibility === OpportunityVisibility.PRIVATE,
  isRecurring: false,
  recurrencePattern: null,
  visibility: values.visibility,
  locationDetails: values.locationDetails,
})

const mapEventGroupToOpportunityTemplateGroup = (
  group: EventGroup,
): Omit<OpportunityTemplatePersonGroupPayload, 'opportunityTemplateId'> => ({
  groupId: group.groupId,
  associationType: group.associationType,
  approvedMembersOnly: group.approvedMembersOnly,
})

export const AddEditOpportunityTemplatePrompt = ({ showing, close, complete, opportunityTemplate }: Props) => {
  const { groups: initialGroups, loading: groupsLoading } = useGroups() as { groups: Group[]; loading: boolean }
  const groups = useEnabledGroups(initialGroups)
  const [visibilityGroups, setVisibilityGroups] = React.useState<EventGroup[]>([])
  const [getOpportunityTemplateGroups, { loading: templateGroupsLoading }] = useGetOpportunityTemplateGroups()

  const values = useMemo(
    () => (opportunityTemplate ? mapOpportunityTemplateToValues(opportunityTemplate, visibilityGroups) : defaultValues),
    [opportunityTemplate, visibilityGroups],
  )

  useEffect(() => {
    opportunityTemplate &&
      getOpportunityTemplateGroups(opportunityTemplate.id).then(opportunityTemplateGroups => {
        setVisibilityGroups(
          opportunityTemplateGroups.map(opportunityTemplateGroup => ({
            groupId: opportunityTemplateGroup.group.id,
            name: opportunityTemplateGroup.group.name,
            associationType: opportunityTemplateGroup.associationType,
            approvedMembersOnly: opportunityTemplateGroup.approvedMembersOnly,
            closed: opportunityTemplateGroup.group.closed,
          })) as EventGroup[],
        )
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = useCallback(
    (data: EventOverviewScreenValues) =>
      complete({
        values: mapValuesToOpportunityTemplate(data, opportunityTemplate?.id),
        visibilityGroups: data.visibilityGroups.map(mapEventGroupToOpportunityTemplateGroup),
      }),
    [complete, opportunityTemplate],
  )

  if (groupsLoading || templateGroupsLoading) {
    return <Loading />
  }
  return (
    <FormModal
      id="opportunity-template-prompt"
      showing={showing}
      close={close}
      title={(opportunityTemplate ? 'Edit' : 'Add') + ' Opportunity Template'}
    >
      <EventOverviewScreen
        values={values}
        onBack={close}
        isNewEvent={!opportunityTemplate}
        onSubmit={handleSubmit}
        groups={groups}
        isTemplate={true}
        isDraftable={false}
      />
    </FormModal>
  )
}

export default AddEditOpportunityTemplatePrompt
