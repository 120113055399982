import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(2),
    width: 100,
    height: 100,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '70vh',
    height: '100%',
    width: '100%',
  },
}))

export default function Loading(props) {
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <CircularProgress className={classes.progress} size={props.size || 150} />
    </div>
  )
}
