import { isEmpty } from 'lodash'
import React, { useMemo } from 'react'
import { Redirect } from 'react-router'

import { useHasRole } from 'auth/hooks'
import { INTERNAL_ADMIN, INTERNAL_SUPER_ADMIN, SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import { useFeatureEnabled, useOrganizationSettingsTabs } from 'core/feature/hooks'

import { ChampionDetail } from 'champion/pages/Detail'
import { Champions as ChampionsOld } from 'champion/pages/Champions.old'
import { Champions } from 'champion/pages/Champions'
import { Registrants } from 'registrant/pages/Registrants'
import { ProfileViewOld } from 'champion/pages/ProfileView.old'
import Activities from 'tracking/activity/components/Activities'
import Opportunities from 'volunteering/opportunities/components/Opportunities'
import OpportunitiesOld from 'volunteering/opportunities/components/OpportunitiesOld'
import MyTeam from 'admins/team/components/MyTeam'
import Kiosk from 'kiosk/components/StartKiosk'
import FeatureSettings from 'core/feature/components/FeatureSettings'
import OrganizationSettings from 'core/feature/components/OrganizationSettings'
import EventDetails from 'Event/scenes/events/detail'
import EventDetailsVolunteers from 'Event/scenes/events/detail/volunteers'
import CreateEvent from 'Event/redux/containers/CreateEventContainer'
import EditEvent from 'Event/redux/containers/EditEventContainer'
import Organizations from 'volunteering/organizations/components/Organizations'
import MentorshipAccount from 'Mentorship/scenes/MyAccount'
import {
  MentorMeetingOverview,
  MentorshipMatches,
  MentorshipMeetings,
  MentorshipPrograms,
  MentorshipSurveyDetails,
  MentorshipSurveys,
  NewMentorshipMatches,
  NewMentorshipMeetings,
  NewMentorshipPrograms,
  NewMentorshipSurveys,
} from 'new-mentorship/pages/'
import { Credentials } from 'credential/pages/Credentials'
import { CredentialDetails } from 'credential/pages/CredentialDetails'
import { CredentialVolunteers } from 'credential/pages/CredentialVolunteers'
import { DonationsOverview } from 'donation/pages/DonationsOverview'
import { DonationDetail } from 'donation/pages/Detail'
import { Campaigns } from 'donation/campaigns/pages/Campaigns'
import TestEditCampaign from 'donation/campaigns/pages/TestEditCampaign'
import { CampaignDemoSelector } from 'donation/campaigns/pages/DemoSelector'
import { CampaignDemo } from 'donation/campaigns/pages/Demo'
import { CampaignDetails } from 'donation/campaigns/pages/CampaignDetails'
import { DonationStripeConnect } from 'donation/pages/StripeConnect'
import { OrgPeopleOverview } from 'people/pages/OrgPeopleOverview'
import { AllPeopleOverview } from 'people/pages/AllPeopleOverview'
import { MergeAccounts } from 'people/pages/MergeAccounts'
import { InvitationsOverview } from 'people/pages/InvitationsOverview'
import { InvitationDetail } from 'people/pages/InvitationDetail'
import { PersonDetail } from 'people/pages/PersonDetail'
import { PersonNameUpdate } from 'people/pages/PersonNameUpdate'
import { PersonAddContact } from 'people/pages/PersonAddContact'
import EventsCalendar from 'Event/scenes/events/Events'
import CreateTask from 'volunteering/tasks/components/CreateTask'
import HelpingHands from 'volunteering/tasks/components/HelpingHands'
import { GroupsOverviewOld } from 'group/pages/GroupsOverview.old'
import { GroupsDetail } from 'group/pages/GroupsDetail'
import { GroupsQuestionSets } from 'group/pages/GroupsQuestionSets'
import TaskAddVolunteer from 'volunteering/tasks/components/TaskAddVolunteer'
import Task from 'volunteering/tasks/components/Task'
import UpdateTask from 'volunteering/tasks/components/UpdateTask'
import TaskPersonRequests from 'volunteering/tasks/components/TaskPersonRequests'
import { Questionnaires, QuestionSetOverview } from 'question-sets/pages/'
import { Roles } from 'volunteer-role/pages'
import { QuestionSetEdit } from 'question-sets/pages/QuestionSetEdit'
import { QuestionSetNew } from 'question-sets/pages/QuestionSetNew'
import { QuestionSetDetails } from 'question-sets/pages/QuestionSetDetails'
import { QuestionnaireDetails } from 'question-sets/pages/QuestionnaireDetails'
import BuySmsCredits from 'messages/pages/BuySmsCredits'
import { ProductsOverview } from 'core/stripe-products/pages/ProductsOverview'
import ProductForm from 'core/stripe-products/pages/ProductForm'
import { BadContactsOverview } from 'messages/pages/BadContactsOverview'
import { OptedOutContactsOverview } from 'messages/pages/OptedOutContactsOverview'
import { WhitelistedPhoneNumbersOverview } from 'messages/pages/WhitelistedPhoneNumbersOverview'
import AddSMSCredits from 'messages/pages/AddSMSCredits'
import useSettingsEnabledForSuperAdmin from 'core/feature/hooks/useSettingsEnabledForSuperAdmin'
import { TestersOverview } from 'people/pages/TestersOverview'
import { CredentialIDs } from 'credential-ids/pages/CredentialIDs'
import { CredentialIDDetails } from 'credential-ids/pages/CredentialIDDetails'
import ExternalDatafeeds from 'external-datafeed/pages/ExternalDatafeeds'
import { SurveyDetails } from 'surveys/components'
import WebhooksResponses from 'integrations/components/WebhooksResponses'
import { ProfileView } from 'champion/pages/ProfileView'
import { ActivityLog } from 'tracking/activity/pages/ActivityLog'
import { Documents } from 'documents/pages/Documents'
import { DocumentTypes } from 'documents/pages/DocumentTypes'
import { TripPlannerOld } from 'volunteering/trips/components'
import { Chat, Chats } from 'chats'
import MentorshipSurveyEditor from 'new-mentorship/components/MentorshipSurveyEditor'
import QuestionSetSurveyEditor from 'question-sets/pages/QuestionSetSurveyEditor'
import GroupSurveyQuestionSetEditor from 'group/pages/GroupSurveyQuestionSetEditor'
import EventSurveyQuestionSetEditor from 'Event/components/EventSurveyQuestionSetEditor'
import { Admins } from 'admins/team/pages/Admins'
import {
  AllMessagesOverview,
  AutomatedMessageOverview,
  AutomatedMessages,
  BulkMessagesOverview,
  CustomMessages,
  MessageDetail,
  MessageOverview,
} from 'messages/pages'
import { GroupsOverview } from 'group/pages/GroupsOverview'
import ListOfEvents from 'Event/pages/ListOfEvents'
import { Tags } from 'tags/pages'
import { TripPlanner } from 'volunteering/trips/components/TripPlanner'
import EventsDrilldown from 'Event/pages/EventsDrilldown'
import Waivers from 'credential/pages/Waivers'
import { Locations } from 'locations/components/Locations'
import OpportunityDrilldown from 'Event/pages/OpportunityDrilldown'
import Integrations from 'integrations/Integrations'
import Organization from 'organization/Organization'
import OpportunityTemplates from 'volunteering/opportunities/components/OpportunityTemplates'
import WaiverAddEdit from '../../credential/pages/WaiverAddEdit'

//TODO this should probably be broken up into a bunch of area-specific "useRoutes()" hooks
export default function useRoutes() {
  const isInternalAdmin = useHasRole(INTERNAL_ADMIN)
  const isSuperAdmin = useHasRole(SUPER_ADMIN)
  const isInternalSuperAdmin = useHasRole(INTERNAL_SUPER_ADMIN)
  const isMentorshipEnabled = useFeatureEnabled('Mentorship')
  const isNewMentorshipEnabled = useFeatureEnabled('NewMentorship_v3')
  const isSchedulingEnabled = useFeatureEnabled('EventScheduling')
  const isDonationEnabled = useFeatureEnabled('Donations')
  const isBulkMessagingEnabled = useFeatureEnabled('BulkMessaging')
  const isHelpingHandsEnabled = useFeatureEnabled('HelpingHands')
  const isTripPlannerEnabled = useFeatureEnabled('TripPlanner')
  const isGroupsEnabled = useFeatureEnabled('Groups')
  const isIdCredentialsEnabled = useFeatureEnabled('CredentialIDs')
  const isCollapsingTablesEnabled = useFeatureEnabled('CollapsingTables')
  const isCustomerDatafeedsEnabled = useFeatureEnabled('CustomerDatafeed')
  const isNewChampionsEnabled = useFeatureEnabled('NewChampions')
  const isApiKeyEnabled = useFeatureEnabled('ApiKey')
  const isRegistrantsEnabled = useFeatureEnabled('Registrants')
  const isNewVolunteerProfileEnabled = useFeatureEnabled('NewVolunteerProfile')
  const isNewQuestionnairesEnabled = useFeatureEnabled('NewQuestionnaires')
  const hasOrgSettingsTabs = !isEmpty(useOrganizationSettingsTabs())
  const isSettingsEnabledForSuperAdmin = useSettingsEnabledForSuperAdmin()
  const isSettingsEnabled = isInternalAdmin || isSettingsEnabledForSuperAdmin
  const isQuestionnaireAsSurveyEnabled = useFeatureEnabled('QuestionnaireAsSurvey')
  const isNewActivityLogEnabled = useFeatureEnabled('NewActivityLog')
  const isDocumentManagementEnabled = useFeatureEnabled('DocumentManagement')
  const isNewQuestionnairesEditorEnabled = useFeatureEnabled('NewQuestionnairesEditor')
  const isRolesManagementEnabled = useFeatureEnabled('RolesManagement')
  const isChatsEnabled = useFeatureEnabled('Chats')
  const isNewCustomMessagesEnabled = useFeatureEnabled('NewCustomMessages')
  const isNewAdminsEnabled = useFeatureEnabled('NewAdmins')
  const isNewGroupsPageEnabled = useFeatureEnabled('NewGroupsPage')
  const isListOfEventsEnabled = useFeatureEnabled('ListOfEvents')
  const isTagsManagementEnabled = useFeatureEnabled('NewTags')
  const isNewTripPlannerEnabled = useFeatureEnabled('NewTripPlanner')
  const isNewWaiversEnabled = useFeatureEnabled('NewWaiversTable')
  const isNewEventsDrilldownEnabled = useFeatureEnabled('NewEventsDrilldown')
  const isOpportunityDrilldownEnabled = useFeatureEnabled('OpportunityDrilldown')
  const isNewAutomatedMessagesEnabled = useFeatureEnabled('NewAutomatedMessages')
  const isLocationsManagementEnabled = useFeatureEnabled('LocationsManagement')
  const isLglIntegrationEnabled = useFeatureEnabled('LglIntegration')
  const isOrgTabForSuperAdminsEnabled = useFeatureEnabled('OrganizationTabForSuperAdmins')
  const isOrganizationTabEnabled = isInternalAdmin || (isSuperAdmin && isOrgTabForSuperAdminsEnabled)
  const isOpportunityTemplatesEnabled = useFeatureEnabled('OpportunityTemplates')
  const isNewAddEditWaiverUiEnabled = useFeatureEnabled('NewAddEditWaiverUi')

  const hasToken = localStorage.getItem('token')

  return useMemo(() => {
    const routes = [
      { path: '/activities', component: isNewActivityLogEnabled ? ActivityLog : Activities },
      { exact: true, path: '/opportunities', component: isCollapsingTablesEnabled ? Opportunities : OpportunitiesOld },
      { exact: true, path: '/admins', component: isNewAdminsEnabled ? Admins : MyTeam },
      { exact: true, path: '/team/admins', render: () => <Redirect to="/admins" /> },
      { path: '/kiosk', component: Kiosk },
      { path: '/my/account', component: MentorshipAccount },
    ]
    if (isOrganizationTabEnabled) {
      Array.prototype.push.apply(routes, [{ exact: true, path: '/organization', component: Organization }])
    }

    if (isListOfEventsEnabled) {
      Array.prototype.push.apply(routes, [{ exact: true, path: '/list-of-events', component: ListOfEvents }])
    }
    if (isSchedulingEnabled) {
      Array.prototype.push.apply(routes, [
        { exact: true, path: '/events/:viewType?', component: EventsCalendar },
        {
          exact: true,
          path: '/event/new-event/questions/new',
          component: EventSurveyQuestionSetEditor,
        },
        {
          exact: true,
          path: '/event/new-event/questions/:id/edit',
          component: EventSurveyQuestionSetEditor,
        },
        { path: '/event/new-event/:stepTitle', component: CreateEvent },
      ])

      Array.prototype.push.apply(routes, [
        {
          exact: true,
          path: '/events/:encodedOccurrence/:orgId',
          component: isNewEventsDrilldownEnabled ? EventsDrilldown : EventDetails,
        },
        {
          exact: true,
          path: '/events/:encodedOccurrence/:orgId/volunteers',
          component: isNewEventsDrilldownEnabled ? EventsDrilldown : EventDetailsVolunteers,
        },
        {
          exact: true,
          path: '/events/:encodedOccurrence/edit-event/questions/new',
          component: EventSurveyQuestionSetEditor,
        },
        {
          exact: true,
          path: '/events/:encodedOccurrence/edit-event/questions/:id/edit',
          component: EventSurveyQuestionSetEditor,
        },
        { path: '/events/:encodedOccurrence/edit-event/:stepTitle', component: EditEvent },
      ])
    }

    if (isOpportunityDrilldownEnabled) {
      routes.push({
        exact: true,
        path: '/opportunities/:encodedOccurrence',
        component: OpportunityDrilldown,
      })
      routes.push({
        exact: true,
        path: '/offers/:encodedOccurrence',
        component: OpportunityDrilldown,
      })
    }
    if (isOpportunityTemplatesEnabled) {
      routes.push({
        exact: true,
        path: '/opportunity-templates',
        component: OpportunityTemplates,
      })
    }

    if (isNewAddEditWaiverUiEnabled) {
      routes.push(
        {
          exact: true,
          path: '/waivers/new',
          component: WaiverAddEdit,
        },
        {
          exact: true,
          path: '/waivers/:id/edit',
          component: WaiverAddEdit,
        },
      )
    }

    if (isSuperAdmin) {
      Array.prototype.push.apply(routes, [
        {
          exact: true,
          path: '/questionnaires',
          component: isNewQuestionnairesEnabled ? Questionnaires : QuestionSetOverview,
        },
        {
          exact: true,
          path: '/questionnaires/new',
          component: isNewQuestionnairesEditorEnabled ? QuestionSetSurveyEditor : QuestionSetNew,
        },
        {
          exact: true,
          path: '/questionnaires/:id',
          component: isQuestionnaireAsSurveyEnabled
            ? SurveyDetails
            : isNewQuestionnairesEnabled
            ? QuestionnaireDetails
            : QuestionSetDetails,
        },
        {
          exact: true,
          path: '/questionnaires/:id/edit',
          component: isNewQuestionnairesEditorEnabled ? QuestionSetSurveyEditor : QuestionSetEdit,
        },
      ])
    }

    if (isSuperAdmin && isRolesManagementEnabled) {
      Array.prototype.push.apply(routes, [
        {
          exact: true,
          path: '/roles',
          component: Roles,
        },
      ])
      if (isDocumentManagementEnabled) {
        Array.prototype.push.apply(routes, [
          {
            exact: true,
            path: '/documents',
            component: DocumentTypes,
          },
          {
            exact: true,
            path: '/documents/:type',
            component: Documents,
          },
        ])
      }
    }

    if (isSuperAdmin && isTagsManagementEnabled) {
      routes.push({
        exact: true,
        path: '/tags',
        component: Tags,
      })
    }

    if (isSuperAdmin && isLocationsManagementEnabled) {
      routes.push({
        exact: true,
        path: '/locations',
        component: Locations,
      })
    }

    if (isInternalAdmin) {
      Array.prototype.push.apply(routes, [
        { exact: true, path: '/people', component: OrgPeopleOverview },
        { exact: true, path: '/invitations', component: InvitationsOverview },
        { exact: true, path: '/invitations/:id', component: InvitationDetail },
        { exact: true, path: '/people/:id', component: PersonDetail },
        { exact: true, path: '/people/:id/edit', component: PersonNameUpdate },
        { exact: true, path: '/people/:id/add-contact', component: PersonAddContact },
      ])
    }

    if (isInternalSuperAdmin) {
      Array.prototype.push.apply(routes, [
        { exact: true, path: '/all-people', component: AllPeopleOverview },
        { exact: true, path: '/merge-accounts', component: MergeAccounts },
        { exact: true, path: '/testers', component: TestersOverview },
      ])
    }

    Array.prototype.push.apply(routes, [
      { exact: true, path: '/champion/:id', component: isNewVolunteerProfileEnabled ? ProfileView : ProfileViewOld },
    ])

    if (isGroupsEnabled) {
      Array.prototype.push.apply(routes, [
        { exact: true, path: '/groups', component: isNewGroupsPageEnabled ? GroupsOverview : GroupsOverviewOld },
        { exact: true, path: '/groups/:id', component: GroupsDetail },
        { exact: true, path: '/groups/:id/questionnaires', component: GroupsQuestionSets },
        { exact: true, path: '/groups/:id/questionnaires/new', component: GroupSurveyQuestionSetEditor },
        {
          exact: true,
          path: '/groups/:id/questionnaires/:questionnaireId/edit',
          component: GroupSurveyQuestionSetEditor,
        },
      ])
    }

    if (isInternalAdmin) {
      Array.prototype.push.apply(routes, [{ path: '/organizations', component: Organizations }])
    }

    // settings tabs
    if (isSettingsEnabled) {
      const redirectTo = isInternalSuperAdmin ? '/settings/features' : '/settings/organization'

      Array.prototype.push.apply(routes, [
        { exact: true, path: '/settings', render: () => <Redirect to={redirectTo} /> },
      ])
      if (hasOrgSettingsTabs) {
        Array.prototype.push.apply(routes, [{ path: '/settings/organization', component: OrganizationSettings }])
      }
      if (isInternalAdmin) {
        Array.prototype.push.apply(routes, [{ path: '/settings/features', component: FeatureSettings }])
      }

      if (isInternalSuperAdmin) {
        Array.prototype.push.apply(routes, [
          { exact: true, path: '/settings/stripe-products', component: ProductsOverview },
          { exact: true, path: '/settings/stripe-products/:productCode', component: ProductForm },
        ])
      }
    }

    if (isMentorshipEnabled) {
      routes.push(
        ...[
          {
            exact: true,
            path: '/mentorship/meetings',
            component: isNewMentorshipEnabled ? NewMentorshipMeetings : MentorshipMeetings,
          },
          {
            exact: true,
            path: '/mentorship/matches',
            component: isNewMentorshipEnabled ? NewMentorshipMatches : MentorshipMatches,
          },
          {
            path: '/mentorship/programs',
            component: isNewMentorshipEnabled ? NewMentorshipPrograms : MentorshipPrograms,
          },
        ],
      )

      routes.push(
        ...[
          { path: '/mentorship/meetings/:id', component: MentorMeetingOverview },
          { exact: true, path: '/mentorship/meetings', component: MentorshipMeetings },
          { exact: true, path: '/mentorship/matches', component: NewMentorshipMatches },
          { exact: true, path: '/mentorship/programs', component: MentorshipPrograms },
          { exact: true, path: '/mentorship/surveys/new', component: MentorshipSurveyEditor },
          { exact: true, path: '/mentorship/surveys/:surveyId/edit', component: MentorshipSurveyEditor },
          { exact: true, path: '/mentorship/surveys/:id', component: MentorshipSurveyDetails },
          {
            exact: true,
            path: '/mentorship/surveys',
            component: isNewQuestionnairesEnabled ? NewMentorshipSurveys : MentorshipSurveys,
          },
          { exact: true, path: '/mentorship', render: () => <Redirect to="/mentorship/programs" /> },
        ],
      )
    }

    Array.prototype.push.apply(routes, [
      { exact: true, path: '/credentials', component: isNewWaiversEnabled ? Waivers : Credentials },
      { exact: true, path: '/credentials/:id', component: CredentialDetails },
      { exact: true, path: '/credentials/:id/volunteers', component: CredentialVolunteers },
    ])

    if (isDonationEnabled && isSuperAdmin) {
      Array.prototype.push.apply(routes, [
        { exact: true, path: '/donations/overview', component: DonationsOverview },
        { exact: true, path: '/donations/stripe-connect', component: DonationStripeConnect },
        { path: '/donations/:id', component: DonationDetail },
      ])

      Array.prototype.push.apply(routes, [
        { exact: true, path: '/campaigns', component: Campaigns },
        { path: '/campaigns/new', component: TestEditCampaign },
        { exact: true, path: '/campaigns/demo', component: CampaignDemoSelector },
        { exact: true, path: '/campaigns/demo/opportunity/:opportunityId/hours/:hours', component: CampaignDemo },
        { path: '/campaigns/:campaignId/:campaignEventType?', component: CampaignDetails },
      ])
    }

    Array.prototype.push.apply(routes, [
      { exact: true, path: '/champions', component: isNewChampionsEnabled ? Champions : ChampionsOld },
      { path: '/champions/:id', component: ChampionDetail },
    ])

    if (isRegistrantsEnabled) {
      routes.push({ exact: true, path: '/registrants', component: Registrants })
    }

    if (isCustomerDatafeedsEnabled) {
      Array.prototype.push.apply(routes, [{ path: '/datafeeds', component: ExternalDatafeeds }])
    }

    if (isIdCredentialsEnabled) {
      Array.prototype.push.apply(routes, [
        { exact: true, path: '/credential-ids', component: CredentialIDs },
        { exact: true, path: '/credential-ids/:id', component: CredentialIDDetails },
      ])
    }

    // messages drawer
    if (isInternalAdmin || isBulkMessagingEnabled || isNewAutomatedMessagesEnabled || isInternalSuperAdmin) {
      if (isInternalAdmin) {
        routes.push({ exact: true, path: '/messages/:id', component: MessageDetail })
      }

      if (isNewAutomatedMessagesEnabled) {
        routes.push(
          {
            exact: true,
            path: '/messages',
            component: AutomatedMessages,
          },
          { exact: true, path: '/messages/:date/:templateId', component: AutomatedMessageOverview },
        )
      } else if (isInternalAdmin) {
        routes.push({
          exact: true,
          path: '/messages',
          component: AllMessagesOverview,
        })
      } else {
        const messagesRedirectTo = isBulkMessagingEnabled ? '/custom-messages' : '/bad-contacts'
        routes.push({ exact: true, path: '/messages', render: () => <Redirect to={messagesRedirectTo} /> })
      }

      if (isInternalSuperAdmin) {
        Array.prototype.push.apply(routes, [
          { path: '/bad-contacts', component: BadContactsOverview },
          { path: '/opted-out-phone-numbers', component: OptedOutContactsOverview },
          { path: '/white-listed-phone-numbers', component: WhitelistedPhoneNumbersOverview },
          { path: '/add-sms-credits', component: AddSMSCredits },
        ])
      }

      if (isBulkMessagingEnabled) {
        Array.prototype.push.apply(routes, [
          {
            path: '/custom-messages',
            component: isNewCustomMessagesEnabled ? CustomMessages : BulkMessagesOverview,
            exact: true,
          },
          { path: '/custom-messages/:id', component: MessageOverview },
          { path: '/buy-sms-credits', component: BuySmsCredits },
        ])
      }
    }

    if (isHelpingHandsEnabled) {
      Array.prototype.push.apply(routes, [
        { exact: true, path: '/tasks/new', component: CreateTask },
        { exact: true, path: '/tasks/:id/add-volunteer', component: TaskAddVolunteer },
        { exact: true, path: '/helping-hands', component: HelpingHands },
        { exact: true, path: '/task-persons/:id', component: TaskPersonRequests },
        { exact: true, path: '/tasks/:id', component: Task },
        { exact: true, path: '/tasks/:id/update', component: UpdateTask },
      ])
    }

    if (isTripPlannerEnabled) {
      Array.prototype.push.apply(routes, [
        { exact: true, path: '/trip-planner', component: isNewTripPlannerEnabled ? TripPlanner : TripPlannerOld },
      ])
    }

    if (isApiKeyEnabled) {
      Array.prototype.push.apply(routes, [
        { exact: true, path: '/webhooks/:id/event-responses', component: WebhooksResponses },
      ])
    }
    if (isApiKeyEnabled || (isLglIntegrationEnabled && isSuperAdmin)) {
      routes.push({ exact: true, path: '/integrations', component: Integrations })
    }

    if (isChatsEnabled) {
      routes.push({ exact: true, path: '/chats', component: Chats })
      routes.push({ exact: true, path: '/chats/:id', component: Chat })
    }

    let defaultRoute
    if (hasToken) {
      defaultRoute = '/admins'
    } else if (isInternalAdmin) {
      defaultRoute = '/organizations'
    } else if (isMentorshipEnabled) {
      defaultRoute = '/mentorship/programs'
    } else {
      defaultRoute = '/champions'
    }

    return {
      routes,
      defaultRoute,
    }
  }, [
    isNewActivityLogEnabled,
    isCollapsingTablesEnabled,
    isNewAdminsEnabled,
    isOrganizationTabEnabled,
    isListOfEventsEnabled,
    isSchedulingEnabled,
    isOpportunityDrilldownEnabled,
    isOpportunityTemplatesEnabled,
    isNewAddEditWaiverUiEnabled,
    isSuperAdmin,
    isRolesManagementEnabled,
    isTagsManagementEnabled,
    isLocationsManagementEnabled,
    isInternalAdmin,
    isInternalSuperAdmin,
    isNewVolunteerProfileEnabled,
    isGroupsEnabled,
    isSettingsEnabled,
    isMentorshipEnabled,
    isNewWaiversEnabled,
    isDonationEnabled,
    isNewChampionsEnabled,
    isRegistrantsEnabled,
    isCustomerDatafeedsEnabled,
    isIdCredentialsEnabled,
    isBulkMessagingEnabled,
    isNewAutomatedMessagesEnabled,
    isHelpingHandsEnabled,
    isTripPlannerEnabled,
    isApiKeyEnabled,
    isLglIntegrationEnabled,
    isChatsEnabled,
    hasToken,
    isNewEventsDrilldownEnabled,
    isNewQuestionnairesEnabled,
    isNewQuestionnairesEditorEnabled,
    isQuestionnaireAsSurveyEnabled,
    isDocumentManagementEnabled,
    isNewGroupsPageEnabled,
    hasOrgSettingsTabs,
    isNewMentorshipEnabled,
    isNewCustomMessagesEnabled,
    isNewTripPlannerEnabled,
  ])
}
