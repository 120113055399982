import React from 'react'
import { RadioGroup } from 'formik-material-ui'
import { FormControlLabel, Radio } from '@material-ui/core'
import { Field } from 'formik'
import { makeStyles } from '@material-ui/core/styles'

export interface RadioOption {
  value: string
  label: string
}

const useStyles = makeStyles({
  checkboxLabel: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  checkboxContainer: {
    marginLeft: 0,
  },
  checkbox: {},
  radio: {
    padding: '5px 9px',
  },
  checkboxChecked: {
    '&$checkbox, &$radio': {
      color: '#0F5DB5',
    },
  },
})

export const RadioGroupFormField = ({
  options,
  name,
  disabled,
}: {
  options: RadioOption[]
  name: string
  disabled?: boolean
}) => {
  const classes = useStyles()
  return (
    <Field component={RadioGroup} name={name}>
      {options.map(({ value, label }) => (
        <FormControlLabel
          classes={{ root: classes.checkboxContainer, label: classes.checkboxLabel }}
          key={value}
          value={value}
          control={
            <Radio
              size="small"
              classes={{ root: classes.radio, checked: classes.checkboxChecked }}
              disabled={disabled}
            />
          }
          label={label}
        />
      ))}
    </Field>
  )
}

export default RadioGroupFormField
