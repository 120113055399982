import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Opportunity, OpportunityPayload, OpportunityVisibility } from 'Event/interfaces'
import { FormModal } from 'components/FormModal'
import { pick } from 'lodash'
import { EventDateAndRoleScreen, EventDateScreenValues } from 'Event/components/EventDateAndRoleScreen'
import { EventOverviewScreen, EventOverviewScreenValues } from 'Event/components/EventOverviewScreen'
import { EventRegistrationDetailsScreen } from 'Event/components/EventRegistrationDetailsScreen'
import { useGroups } from 'group/hooks/useGroups'
import { Group } from 'Event/components/opportunity/GroupPicker/types'
import { useEnabledGroups } from 'Event/components/opportunity/GroupPicker/hooks'
import { useFeatureEnabled } from 'core/feature/hooks'
import { useGetEventGroups } from 'Event/hooks/useGetEventGroups'
import { useIsTester } from 'auth/hooks'
import Loading from 'civic-champs-shared/core/Loading'
import { OpportunityTemplatePersonGroup } from 'volunteering/opportunities/interfaces'
import useGetOpportunityTemplateGroups from 'volunteering/opportunities/hooks/useGetOpportunityTemplateGroups'
import { useRolesCollection } from 'volunteer-role/hooks'
import { Role } from 'volunteer-role/types'
import {
  EventAdditionalInformationScreen,
  EventAdditionalInformationScreenValues,
} from 'Event/components/EventAdditionalInformationScreen'
import {
  EventPayload,
  FormData,
  getFormValuesFromOpportunityTemplate,
  mapFormDataToEventPayload,
  mapFormDataToOpportunityPayload,
  mapGroups,
  mapOverviewValuesToFormData,
} from 'Event/helpers/add-edit-helpers'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'
import { OpportunityResponseWithRecurrenceInfo } from 'Event/interfaces/interfaceCreateEditEvent'
import useGetEventQuestionSets from 'Event/hooks/useGetEventQuestionSets'
import { encodeOccurrenceFromEvent } from 'Event/helpers/encodeOccurrence'
import { useGetFormDataFromEvent } from '../hooks/useGetFormDataFromEvent'
import { useShowPrompt } from '../../civic-champs-shared/core/modal/hooks'
import EditRecurringEventConfirmDialog from './EditRecurringEventConfirmDialog'
import { RECURRING_TYPE } from '../../civic-champs-shared/core/utils/constants/RECURRING_TYPE_OPTIONS'

export const AddEditEventPrompt = ({
  showing,
  close,
  complete,
  event,
}: {
  showing: boolean
  complete: (value: OpportunityPayload | EventPayload) => void
  close: () => void
  event?: Opportunity | OpportunityResponseWithRecurrenceInfo
}) => {
  const getFormDataFromEvent = useGetFormDataFromEvent()
  const showEditRecurringPrompt = useShowPrompt(EditRecurringEventConfirmDialog)
  const [isNewEvent, isDraftable] = useMemo(() => {
    if (!event) {
      return [true, true]
    } else {
      return [false, (event as OpportunityResponseWithRecurrenceInfo).schedulable && !event.published]
    }
  }, [event])
  const currentOrg = useCurrentOrg()
  const [screen, setScreen] = useState(0)
  const { groups: initialGroups, loading: groupsLoading } = useGroups() as { groups: Group[]; loading: boolean }
  const groups = useEnabledGroups(initialGroups)
  const groupsEnabled = useFeatureEnabled('Groups')
  const [getEventGroups, { loading: eventGroupsLoading }] = useGetEventGroups()
  const [fetchOpportunityTemplateGroups] = useGetOpportunityTemplateGroups()
  const { loading: isTesterLoading, isTester: isTest } = useIsTester()
  const isQuestionnaireAsSurveyEnabled = useFeatureEnabled('QuestionnaireAsSurvey')
  const [{ roles, initiallyLoaded: rolesLoaded }, { addRole }] = useRolesCollection({ reporting: false })
  const titles = useMemo(
    () => [(event ? 'Edit' : 'Add') + ' Event', 'Event Overview', 'Additional Information', 'Registration Details'],
    [event],
  )
  const [getQuestionSets, { result: questionSets, loading: questionSetsLoading }] = useGetEventQuestionSets()
  useEffect(() => {
    if (event && (event as OpportunityResponseWithRecurrenceInfo).schedulable) {
      getQuestionSets(encodeOccurrenceFromEvent(event)).then(questionSets =>
        setFormData(getFormDataFromEvent({ event, questionSets, isQuestionnaireAsSurveyEnabled })),
      )
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const initialFormData = useMemo(() => {
    return getFormDataFromEvent({ event, questionSets, isQuestionnaireAsSurveyEnabled })
  }, [event, getFormDataFromEvent, isQuestionnaireAsSurveyEnabled, questionSets])

  const [formData, setFormData] = useState<FormData>(initialFormData)

  useEffect(() => {
    event &&
      getEventGroups(event.id).then(eventGroups => {
        const groups = mapGroups(eventGroups)
        setFormData(data => ({
          ...data,
          [event.visibility === OpportunityVisibility.SELECT_GROUPS_ONLY ? 'visibilityGroups' : 'onboardingGroups']:
            groups,
        }))
      })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setFormData(data => ({
      ...data,
      isTest,
    }))
  }, [isTest])

  const dateValues: EventDateScreenValues = useMemo(
    () => ({
      ...pick(formData, [
        'startDate',
        'endDate',
        'startTime',
        'endTime',
        'opportunityTemplate',
        'recurringType',
        'roles',
      ]),
      isSchedulable: formData.isSchedulable ? 'true' : 'false',
    }),
    [formData],
  )
  const overviewValues = useMemo(
    () =>
      ({
        name: formData.name,
        description: formData.description,
        address: formData.address || '',
        city: formData.city || '',
        state: formData.state || '',
        zip: formData.zip || '',
        visibility: formData.visibility,
        geofencing: formData.geofencing,
        visibilityGroups: formData.visibilityGroups,
        locationIsAddress: formData.locationIsAddress ? 'true' : 'false',
        locationDetails: formData.locationDetails || '',
      } as EventOverviewScreenValues),
    [formData],
  )

  const additionalInformationValues: EventAdditionalInformationScreenValues = useMemo(
    () => pick(formData, ['contactEmail', 'contactName', 'contactPhoneNumber', 'instructions']),
    [formData],
  )

  const registrationDetailsValues = useMemo(
    () => pick(formData, ['onboardingGroups', 'questionnaires', 'waivers']),
    [formData],
  )

  const handleOpportunitySubmit = useCallback(
    (values: FormData) => {
      complete(mapFormDataToOpportunityPayload(values))
    },
    [complete],
  )

  const handleEventSubmit = useCallback(
    async (values: FormData, asDraft: boolean = false) => {
      if (!isDraftable && values.recurringType !== RECURRING_TYPE.NEVER) {
        const { editType } = await showEditRecurringPrompt()
        if (editType === 'single') {
          values.recurringType = RECURRING_TYPE.NEVER
        }
        values.editMode = editType
      }
      complete(
        mapFormDataToEventPayload(
          values,
          isQuestionnaireAsSurveyEnabled,
          roles as unknown as Role[],
          currentOrg.timeZone,
          asDraft,
        ),
      )
    },
    [complete, currentOrg.timeZone, isDraftable, isQuestionnaireAsSurveyEnabled, roles, showEditRecurringPrompt],
  )

  const handleSubmit = useCallback(
    (values: Partial<FormData>, asDraft: boolean = false) => {
      const data = {
        ...formData,
        ...values,
      }
      if (data.isSchedulable) {
        return handleEventSubmit(data, asDraft)
      } else {
        return handleOpportunitySubmit(data)
      }
    },
    [formData, handleEventSubmit, handleOpportunitySubmit],
  )

  const handleDateSubmit = useCallback(
    async ({ isSchedulable, ...values }: EventDateScreenValues, asDraft: boolean = false) => {
      let opportunityTemplateGroups: OpportunityTemplatePersonGroup[] = []
      if (values.opportunityTemplate) {
        opportunityTemplateGroups = await fetchOpportunityTemplateGroups(values.opportunityTemplate.id)
      }
      setFormData(data => ({
        ...data,
        ...values,
        isSchedulable: isSchedulable === 'true',
        ...getFormValuesFromOpportunityTemplate({
          opportunityTemplateGroups,
          opportunityTemplate: values.opportunityTemplate,
        }),
      }))
      if (asDraft) {
        handleSubmit({}, true)
      } else {
        setScreen(1)
      }
    },
    [fetchOpportunityTemplateGroups, handleSubmit],
  )

  const handleOverviewSubmit = useCallback(
    (data: EventOverviewScreenValues, asDraft: boolean = false) => {
      const values = mapOverviewValuesToFormData(data)
      if (
        !formData.isSchedulable &&
        (values.visibility === OpportunityVisibility.SELECT_GROUPS_ONLY || !groupsEnabled)
      ) {
        handleSubmit(values)
      } else {
        setFormData(data => ({
          ...data,
          ...values,
        }))
        if (asDraft) {
          handleSubmit({}, true)
        } else {
          setScreen(formData.isSchedulable ? 2 : 3)
        }
      }
    },
    [formData.isSchedulable, groupsEnabled, handleSubmit],
  )

  const handlePreviousScreen = useCallback((values: Partial<FormData>) => {
    setFormData(data => ({
      ...data,
      ...values,
    }))
    setScreen(value => value - 1)
  }, [])

  const handleOverviewBack = useCallback(
    (values: EventOverviewScreenValues) => {
      return handlePreviousScreen(mapOverviewValuesToFormData(values))
    },
    [handlePreviousScreen],
  )

  const handleNextScreen = useCallback(
    (values: EventAdditionalInformationScreenValues, asDraft: boolean = false) => {
      setFormData(data => ({
        ...data,
        ...values,
      }))
      if (asDraft) {
        handleSubmit({}, true)
      } else {
        setScreen(screen => screen + 1)
      }
    },
    [handleSubmit],
  )

  if (groupsLoading || eventGroupsLoading || isTesterLoading || !rolesLoaded || questionSetsLoading) {
    return <Loading />
  }

  return (
    <FormModal id="trip-prompt" showing={showing} close={close} title={titles[screen]}>
      {
        [
          <EventDateAndRoleScreen
            values={dateValues}
            onBack={close}
            isNewEvent={isNewEvent}
            isDraftable={isDraftable}
            onSubmit={handleDateSubmit}
            roles={roles as Role[]}
            addRole={addRole as () => Promise<Role>}
          />,
          <EventOverviewScreen
            values={overviewValues}
            onBack={handleOverviewBack}
            isNewEvent={isNewEvent}
            isDraftable={isDraftable}
            onSubmit={handleOverviewSubmit}
            groups={groups}
            isSchedulable={formData.isSchedulable}
          />,
          <EventAdditionalInformationScreen
            values={additionalInformationValues}
            onBack={handlePreviousScreen}
            isNewEvent={isNewEvent}
            isSchedulable={formData.isSchedulable}
            isDraftable={isDraftable}
            onSubmit={handleNextScreen}
          />,
          <EventRegistrationDetailsScreen
            values={registrationDetailsValues}
            onBack={handlePreviousScreen}
            isNewEvent={isNewEvent}
            isDraftable={isDraftable}
            onSubmit={handleSubmit}
            groups={groups}
            isSchedulable={formData.isSchedulable}
          />,
        ][screen]
      }
    </FormModal>
  )
}

export default AddEditEventPrompt
