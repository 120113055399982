import _ from 'lodash'
import { useCallback } from 'react'

import { useFetch } from 'civic-champs-shared/api/hooks'
import { CreateOrUpdateOrganizationParam } from '../models'

const useCreateOrUpdateOrganization = () => {
  const [request] = useFetch()

  return useCallback(
    async (values: CreateOrUpdateOrganizationParam, organizationId?: number) => {
      const isUpdate = !!organizationId
      // @ts-ignore
      return request({
        method: isUpdate ? 'put' : 'post',
        url: isUpdate ? `/organizations/${organizationId}` : `/organizations`,
        config: {
          headers: { 'X-Api-Version': '2020-01-17' },
          body: _.pick(values, [
            'name',
            'website',
            'address',
            'timeZone',
            'isTest',
            'isMentoringCustomer',
            'sandboxModeEnabled',
          ]),
        },
      })
    },
    [request],
  )
}

export default useCreateOrUpdateOrganization
