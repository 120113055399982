import React from 'react'
import { useDispatch } from 'react-redux'

import { useCurrentOrg, useIsTester } from 'auth/hooks'
import Loading from 'components/Loading'
import { currentOrgUpdated } from 'auth/actions'
import useOrganization from 'volunteering/organizations/hooks/useOrganization'
import useCreateOrUpdateOrganization from 'volunteering/organizations/hooks/useCreateOrUpdateOrganization'
import OrganizationForm from 'volunteering/organizations/components/OrganizationForm'
import PageHeader from 'App/components/PageHeader'
import { makeStyles } from '@material-ui/styles'
import { useGetOrganizationCreateUpdatePayload } from './useGetOrganizationCreateUpdatePayload'

const useStyles = makeStyles({
  wrapper: {
    background: 'white',
    width: '100%',
    height: '100%',
    padding: '20px 40px',
    overflow: 'auto',
  },
})

export default function Organization() {
  const organization = useCurrentOrg()
  const classes = useStyles()
  const { result, loading, called } = useOrganization(organization.id)
  const { isTester, loading: testerLoading } = useIsTester()

  const organizationPayload = useGetOrganizationCreateUpdatePayload(result)

  const createOrUpdateOrganization = useCreateOrUpdateOrganization()

  const dispatch = useDispatch()

  const onSubmit = async (values: any) => {
    const updatedOrg = await createOrUpdateOrganization(values, organization.id)
    dispatch(currentOrgUpdated(updatedOrg))
    alert('Changes were saved successfully!')
  }

  if (!!organization.id && (loading || !called || testerLoading)) {
    return <Loading />
  }

  return (
    <PageHeader title="Organization" subTitle="Edit organizational details" noPadding>
      <div className={classes.wrapper}>
        <OrganizationForm
          justify={'flex-start'}
          onSubmit={onSubmit}
          organization={organizationPayload}
          isTester={isTester}
        />
      </div>
    </PageHeader>
  )
}
