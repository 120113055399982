import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { useCurrentOrg } from 'auth/hooks'
import { RowsReorderModal } from 'core/table/components/RowsReorderModal'
import useGetSortOrder from 'core/table/table-hooks/useGetSortOrder'
import useReorderOpportunityTemplates from 'volunteering/opportunities/hooks/useReorderOpportunityTemplates'

const filter = () => true

export const useReorderOpportunityTemplatesPrompt = (isSchedulable: boolean, onReorder?: () => void) => {
  const showModal = useShowPrompt(RowsReorderModal)
  const reorderOpportunityTemplates = useReorderOpportunityTemplates(isSchedulable, onReorder)
  const organization = useCurrentOrg()
  const [fetchSortOrder] = useGetSortOrder()

  return useCallback(
    async table => {
      const sortOrder = await fetchSortOrder('opportunity_templates')

      const reordered = await showModal({
        table,
        filter,
        title: 'Adjust Opportunity Templates Order',
        description: `Drag  Opportunity Templates or choose other sorting options to adjust the order they will appear throughout ${organization.name}`,
        sortOptions: [
          { name: 'Custom', field: 'order', order: 'asc', isCustom: true },
          { name: 'A-Z', field: 'name', order: 'asc' },
          { name: 'Most Frequently Used', field: 'usageCount', order: 'desc' },
        ],
        sortOrder,
      })

      if (reordered) {
        const { rows, sortOption } = reordered
        await reorderOpportunityTemplates({ rows, sortOption })
      }
    },
    [fetchSortOrder, organization.name, reorderOpportunityTemplates, showModal],
  )
}

export default useReorderOpportunityTemplatesPrompt
