import { makeStyles } from '@material-ui/styles'

export const useAddEditEventStyles = makeStyles({
  sectionTitle: {
    fontFamily: 'Nunito',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px',
    marginBottom: '5px',
    '&$noMargin': {
      marginBottom: 0,
    },
  },
  subSection: {
    '& $dateTimePickers, & $subItem': {
      marginLeft: '40px',
      maxWidth: 'calc(100% - 40px)',
    },
  },
  subItem: {},
  noMargin: {},
  dateTimePickers: {
    display: 'flex',
    gap: '24px',
    width: '440px',
  },
  dateTimePicker: {
    width: '208px',
  },
  section: {
    gap: '10px',
    display: 'flex',
    flexDirection: 'column',
    '&:last-child': {
      marginBottom: '14px',
    },
  },
  switch: {
    display: 'flex',
    margin: '0 0 10px 4px',
    gap: '5px',
    '& > span': {
      fontFamily: 'Nunito',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: '#000000',
    },
  },
  role: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
    gap: '10px',
    alignItems: 'center',
  },
  roleSelector: {
    flexGrow: 2,
  },
  roleQuantity: {
    width: '112px',
    marginLeft: '15px',
  },
  roleDelete: { cursor: 'pointer' },
  addIcon: {
    fontSize: '16px',
    color: '#0F5DB5',
    marginRight: '8px',
  },
  dependentField: {
    marginLeft: '38px',
  },
  unGap: {
    marginTop: '-10px',
  },
})
