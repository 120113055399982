import React from 'react'
import { FieldProps } from 'formik/dist/Field'
import { InputProps, StyledInput as Input } from 'civic-champs-shared/core/StyledInput'

export const StyledInput = ({
  field: { name, value },
  form: { touched, errors, setFieldValue },
  meta,
  ...props
}: FieldProps<string> & Omit<InputProps, 'value' | 'onChange' | 'error'>) => {
  return (
    <Input
      value={value}
      onChange={newValue => setFieldValue(name, newValue)}
      error={touched[name] ? (errors[name] as string) : undefined}
      {...props}
    />
  )
}
