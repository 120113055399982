import React from 'react'
import useGetOnMount from 'civic-champs-shared/api/hooks/useGetOnMount'
import { PeopleOverview } from '../components/PeopleOverview'

export const AllPeopleOverview = props => {
  const [refresh, { loading, result: people }] = useGetOnMount({
    url: `/people`,
    errorMessage: 'Error fetching People',
    emptyValue: [],
  })

  return <PeopleOverview 
    title="All People" 
    loading={loading} 
    people={people}
    refresh={refresh}
  />
}
