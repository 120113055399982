import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { useCreateApiNotification } from 'civic-champs-shared/api/hooks'
import { AddressType, OrganizationLocation, OrganizationLocationReporting } from '../types'
import { useSaveLocation } from 'locations/hooks/useSaveLocation'
import AddEditLocationModal from 'locations/components/AddEditLocationModal'
import { Action } from 'civic-champs-shared/api/hooks/useRemoteCollection'
import { OpportunityPayload, OpportunityVisibility } from '../../Event/interfaces'
import useCreateNonSchedulableOpportunity from '../../volunteering/opportunities/hooks/useCreateNonSchedulableOpportunity'
import useUpdateNonSchedulableOpportunity from '../../volunteering/opportunities/hooks/useUpdateNonSchedulableOpportunity'
import { useCurrentOrg } from '../../auth/hooks'
import moment from 'moment-timezone'
import { encodeOccurrence } from '../../Event/helpers/encodeOccurrence'
import API_ENDPOINTS from '../../Event/constants/API_ENDPOINTS'
import BaseService from '../../Event/services/base.service'
import {
  AssociationType,
  OpportunityOccurrencePersonGroup,
  OpportunityResponse,
} from '../../Event/interfaces/interfaceCreateEditEvent'
import { mapToEventGroup } from '../../Event/scenes/EditEventScene'

export const useAddEditLocationPrompt = ({
  eagerAdd,
  eagerReplace,
}: {
  eagerReplace?: Action<OrganizationLocationReporting>
  eagerAdd?: Action<OrganizationLocationReporting>
} = {}) => {
  const createNotification = useCreateApiNotification()
  const showPrompt = useShowPrompt(AddEditLocationModal)
  const { saveLocation } = useSaveLocation()
  const org = useCurrentOrg()
  const [createOpportunity] = useCreateNonSchedulableOpportunity(org.id) as [
    (opportunity: OpportunityPayload) => Promise<any>,
  ]
  const [updateOpportunity] = useUpdateNonSchedulableOpportunity() as [
    (opportunity: OpportunityPayload, encodedOccurrence: string) => Promise<any>,
  ]
  return useCallback(
    async (
      location?: OrganizationLocation | OrganizationLocationReporting,
      forceEnableCheckIns?: true,
    ): Promise<OpportunityResponse | undefined> => {
      if (location?.encodedOccurrence) {
        const base = new BaseService()
        const eventGroups = await base.getJSON(API_ENDPOINTS.Events.getOccurrenceGroups(location.encodedOccurrence))
        if (location.type === AddressType.Public) {
          location.onboardingGroups = eventGroups
            .filter(
              (group: OpportunityOccurrencePersonGroup) =>
                group.associationType === AssociationType.ADD_PARTICIPANTS_TO_GROUP,
            )
            .map(mapToEventGroup)
        } else {
          location.visibilityGroups = eventGroups
            .filter(
              (group: OpportunityOccurrencePersonGroup) =>
                group.associationType === AssociationType.EVENT_PRIVATE_TO_MEMBERS,
            )
            .map(mapToEventGroup)
        }
      }
      const formData = await showPrompt({ location, forceEnableCheckIns })
      const notification = createNotification('Saving location')
      try {
        let savedLocation
        let response
        if (formData.type === AddressType.Event) {
          savedLocation = await saveLocation(formData)
          if (formData.id) {
            eagerReplace?.({ ...savedLocation, type: AddressType.Event })
          } else {
            eagerAdd?.({ ...savedLocation, type: AddressType.Event })
          }
        } else {
          const event = {
            name: formData.name,
            description: formData.description,
            visibility:
              formData.type === AddressType.Public
                ? OpportunityVisibility.PUBLIC
                : OpportunityVisibility.SELECT_GROUPS_ONLY,
            isTest: false,
            isSchedulable: false,
            address: formData.line1,
            city: formData.city,
            state: formData.state,
            zip: formData.zip,
            locationDetails: formData.locationDetails,
            startsAt: formData.startsAt ? formData.startsAt : moment.tz(org.timeZone).startOf('day').toDate(),
            endsAt: formData.endsAt || null,
            geofencing: formData.geofencing,
            groups: formData.type === AddressType.Public ? formData.onboardingGroups : formData.visibilityGroups,
            locationIsAddress: formData.locationIsAddress,
          } as OpportunityPayload
          if (location?.encodedOccurrence) {
            response = await updateOpportunity(event, location.encodedOccurrence)
          } else {
            response = await createOpportunity(event)
          }

          const newLocation: OrganizationLocationReporting = {
            // we don't know location id and we actually don't need for opportunity locations so we set it to random
            id: location?.id || 100000 + Math.floor(Math.random() * 100000),
            locationId: location?.locationId || 100000 + Math.floor(Math.random() * 100000),
            organizationId: org.id,
            order: location?.order || null,
            name: response.name,
            line1: response.address || response.streetAddress,
            line2: null,
            city: response.city || null,
            county: response.county || null,
            state: response.state || null,
            zipCode: response.zipCode || response.zip || null,
            countryCode: response.countryCode || null,
            geofencing: response.geofencing,
            type: formData.type,
            encodedOccurrence: encodeOccurrence(response.opportunityId, response.startsAt),
            opportunityOccurrenceId: response.id,
            description: response.description,
            startsAt: response.startsAt,
            timesUsedActive: (location as any)?.['timesUsedActive'] || 1,
            timesUsedTotal: (location as any)?.['timesUsedActive'] || 1,
            locationIsAddress: formData.locationIsAddress,
            visibility: formData.type === AddressType.Public ? 'Public' : 'Private',
          }

          if (location?.encodedOccurrence) {
            eagerReplace?.(newLocation)
          } else {
            eagerAdd?.(newLocation)
          }
        }
        notification.onSuccess('Location was saved')

        return response
      } catch (error) {
        notification.onError('Failed to save location', error)
      }
    },
    [
      createNotification,
      createOpportunity,
      eagerAdd,
      eagerReplace,
      org.id,
      org.timeZone,
      saveLocation,
      showPrompt,
      updateOpportunity,
    ],
  )
}

export default useAddEditLocationPrompt
