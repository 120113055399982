import { useCallback } from 'react'
import { useCreateApiNotification, useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import { Action } from 'civic-champs-shared/api/hooks/useRemoteCollection'
import { OrganizationLocationReporting } from 'locations/types'

export const useRemoveLocation = (eagerRemove: Action<OrganizationLocationReporting>) => {
  const [request] = useFetch()
  const createNotification = useCreateApiNotification()
  const currentOrg = useCurrentOrg()

  return useCallback(
    async (location: OrganizationLocationReporting) => {
      const undo = eagerRemove(location)
      const notification = createNotification('Removing locations')
      try {
        // @ts-ignore
        await request({
          method: 'del',
          url: `/organizations/${currentOrg.id}/locations/${location.id}`,
        })

        notification.onSuccess('Location removed!')
      } catch (error) {
        notification.onError(`Could not remove ${location.name || 'selected'} location`, error)
        undo()
      }
    },
    [createNotification, currentOrg.id, eagerRemove, request],
  )
}

export default useRemoveLocation
