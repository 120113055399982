import moment from 'moment'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Form, Formik } from 'formik'
import { Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid, IconButton } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'

import yup from 'civic-champs-shared/utils/yup'
import DraggablePaper from 'civic-champs-shared/core/confirm-dialog/DraggablePaper'
import { AdvancedAutocomplete, ContainedButton, Loading, OutlinedButton } from 'civic-champs-shared/core'
import format from 'civic-champs-shared/utils/format'
import { NamedRef, Person, PersonRef } from 'civic-champs-shared/common/types'
import {
  StyledKeyboardDatePicker,
  StyledKeyboardTimePicker,
} from 'civic-champs-shared/formik/components/StyledDateTimePickers'

import { muiTheme } from 'theme'
import { Activity } from 'champion/utils/interface'
import { useRolesCollection } from 'volunteer-role/hooks'
import { Role } from 'volunteer-role/types'
import DurationEditField from './DurationEditField'
import VolunteerSelector from './VolunteerSelector'
import { useAddOrEditActivityPromptStyle, useBulkAddActivities, useEditActivity } from '../hooks'
import { useGetOpportunities } from '../../../volunteering/opportunities/hooks/useGetOpportunities'
import { Opportunity } from '../../../Event/interfaces'
import { useDateTimeUtils } from '../../../civic-champs-shared/utils/useDateTimeUtils'
import { flatMap } from 'lodash'
import isNull from 'lodash/isNull'
import { useFeatureEnabled } from '../../../core/feature/hooks'
import StyledInput from '../../../civic-champs-shared/core/StyledInput'
import { OpportunityStatus } from '../../../Event/interfaces/interfaceCreateEditEvent'

interface AddOrEditActivityPromptProps {
  activity?: Activity
  opportunity?: Opportunity
  showing: boolean
  complete: (value: any) => void
  close: () => void
}

const validateAutocompleteItem = () =>
  yup.lazy(value =>
    value === '' || value == null
      ? yup.mixed().notRequired().default(undefined)
      : yup.object({
          id: yup.number(),
          name: yup.string().trim().required(),
        }),
  )

const validationSchema = (additionalNotesEnabled = false) =>
  yup.object({
    startDate: yup
      .date()
      .typeError('The date provided is not a valid date format (MM/DD/YYYY).')
      .nullable()
      .required('Please select start date'),
    startTime: yup.date().required('Please select a start time.'),

    endDate: yup
      .date()
      .test('min-by-date', 'The end date cannot occur before the start date.', function (value: any) {
        return value && moment(value).isSameOrAfter(moment(this.parent.startDate), 'day')
      })
      .required('Please enter a end date.')
      .typeError('The date provided is not a valid date format (MM/DD/YYYY).'),
    endTime: yup
      .date()
      .test('min-by-time', 'The end time cannot occur before the start time.', function (value: any) {
        if (!this.parent.startDate || !this.parent.startTime || !value) return true
        const startDate = moment(this.parent.startDate)
        const startTime = moment(this.parent.startTime).year(startDate.year()).dayOfYear(startDate.dayOfYear())
        const endDate = moment(this.parent.endDate)
        const endTime = moment(value).year(endDate.year()).dayOfYear(endDate.dayOfYear())
        return endTime.isSameOrAfter(startTime)
      })
      .required('A end time is required. Please enter a end time.'),
    duration: yup.object().required(),
    volunteers: yup
      .array()
      .of(
        yup.object({
          // @ts-ignore
          id: yup.number().id(),
          givenName: yup.string().trim().required(),
          familyName: yup.string().trim().required(),
        }),
      )
      .min(1, 'Please select one or more volunteers.')
      .required(),
    opportunity: validateAutocompleteItem(), //TODO do we want to warn users if they're removing an opportunity?
    volunteeringRole: validateAutocompleteItem(), //TODO do we want to warn users if they're removing a role?
    ...(additionalNotesEnabled
      ? {
          team: yup.string().trim().nullable().notRequired(),
        }
      : {}),
  })

const getOptionInfo = (option: NamedRef) => ({ left: option.name, id: option.id })

const filterRoles = (roles: Role[], opportunity: Opportunity | null) => {
  if (opportunity?.timeshifts?.length) {
    const timeShiftRoles = flatMap(opportunity.timeshifts, 'roles')
    return roles.filter(role =>
      timeShiftRoles.some(timeShiftRole => (timeShiftRole.volunteering_role_id || timeShiftRole.id) === role.id),
    )
  } else {
    return roles.filter(role => role.isDefault)
  }
}

export function AddOrEditActivityPrompt(props: AddOrEditActivityPromptProps) {
  const { showing, close, complete, activity } = props

  const { unfakeAsLocalTimeForUIAsString, fakeAsLocalTimeForUI, compileDateTime } = useDateTimeUtils()
  const { volunteeringRole, user } = activity || {}
  const opportunity = props.opportunity || activity?.opportunity

  const classes = useAddOrEditActivityPromptStyle()
  const additionalNotesEnabled = useFeatureEnabled('ActivityAdditionalNotes')

  const [startDate, duration, endDate] = useMemo(() => {
    const startDate = activity
      ? fakeAsLocalTimeForUI(moment(activity.occurredAt))
      : moment().set('hour', 12).set('minute', 0).set('second', 0)
    const duration = moment.duration(activity ? activity.hoursVolunteered : 1, 'hour')
    const endDate = startDate.clone().add(duration)
    return [startDate, duration, endDate]
  }, [activity, fakeAsLocalTimeForUI])

  const [{ roles: allRoles, loading: rolesLoading }] = useRolesCollection()
  const roles = useMemo(() => allRoles.filter(role => role.isActive === true), [allRoles])
  const [getOpportunities, { result, loading: opportunitiesLoading }] = useGetOpportunities()
  useEffect(() => {
    getOpportunities({ startDate, endDate, reporting: false })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const opportunities = useMemo(
    () => result.filter(opportunity => (opportunity as any).status !== OpportunityStatus.Draft),
    [result],
  )

  const [editActivity] = useEditActivity()
  const [bulkAddActivities] = useBulkAddActivities()

  const handleSubmit = useCallback(
    async (values: any) => {
      let result

      const commonPayload = {
        occurredAt: unfakeAsLocalTimeForUIAsString(compileDateTime(values.startDate, values.startTime)),
        hoursVolunteered: values.duration.as('hours'),
        volunteeringRoleId: values.volunteeringRole?.id || null,
        opportunityId: values.opportunity?.id || null,
        team: values.team,
      }

      if (activity) {
        const [volunteer] = values.volunteers
        const { cognitoSub } = volunteer
        result = await editActivity({
          id: activity.id,
          volunteer: {
            cognitoSub,
            name: format.name(volunteer),
          },
          ...commonPayload,
        })
      } else {
        result = await bulkAddActivities({
          volunteers: values.volunteers,
          ...commonPayload,
        })
      }

      complete ? complete(result) : close()
    },
    [activity, bulkAddActivities, close, compileDateTime, complete, editActivity, unfakeAsLocalTimeForUIAsString],
  )

  const initialValues = {
    volunteers: user ? [user as Person] : [],
    startDate,
    startTime: startDate,
    endDate,
    endTime: endDate,
    duration,
    opportunity,
    volunteeringRole,
    team: additionalNotesEnabled ? activity?.team : undefined,
  }

  return (
    // @ts-ignore
    <Dialog
      open={showing}
      // @ts-ignore
      PaperComponent={DraggablePaper}
      // @ts-ignore
      PaperProps={{ handle: `#message-users-prompt` }}
      aria-labelledby="draggable-dialog-title"
      maxWidth="xs"
      classes={{ paper: classes.dialog }}
      disableEnforceFocus
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema(additionalNotesEnabled)}
        onSubmit={handleSubmit}
      >
        {({ submitForm, setFieldValue, setFieldTouched, isSubmitting, values, touched, errors }) => {
          const isValid = (
            n: 'startDate' | 'endDate' | 'startTime' | 'endTime' | 'duration',
            v: moment.Moment | moment.Duration | null,
          ) =>
            !isNull(v) &&
            ['startDate', 'endDate', 'startTime', 'endTime', 'duration']
              .filter(name => name !== n)
              .reduce((valid, name) => valid && !isNull((values as any)[name]), true)

          const setDateValue =
            (name: 'startDate' | 'endDate' | 'startTime' | 'endTime' | 'duration') =>
            (value: moment.Moment | moment.Duration) => {
              if (!isValid(name, value)) {
                return setFieldValue(name, value)
              }
              let startDate: moment.Moment
              let endDate: moment.Moment
              switch (name) {
                case 'duration':
                  startDate = compileDateTime(values.startDate, values.startTime)
                  endDate = startDate.clone().add(value as moment.Duration)
                  setFieldValue(name, value)
                  setFieldValue('endDate', endDate)
                  setFieldValue('endTime', endDate.clone())
                  break
                case 'startDate':
                case 'startTime':
                  startDate =
                    name === 'startDate'
                      ? compileDateTime(value as moment.Moment, values.startTime)
                      : compileDateTime(values.startDate, value as moment.Moment)
                  endDate = startDate.clone().add(values.duration)
                  setFieldValue(name, value)
                  setFieldValue('endDate', endDate)
                  setFieldValue('endTime', endDate.clone())
                  break
                case 'endDate':
                case 'endTime':
                  endDate =
                    name === 'endDate'
                      ? compileDateTime(value as moment.Moment, values.endTime)
                      : compileDateTime(values.endDate, value as moment.Moment)
                  startDate = compileDateTime(values.startDate, values.startTime)
                  setFieldValue(name, value)
                  setFieldValue('duration', moment.duration(endDate.diff(startDate)))
                  break
              }
              getOpportunities({
                startDate: unfakeAsLocalTimeForUIAsString(startDate),
                endDate: unfakeAsLocalTimeForUIAsString(endDate),
              })
            }
          return rolesLoading ? (
            <Loading />
          ) : (
            <Form>
              <DialogTitle classes={{ root: classes.title }} disableTypography={true} id="message-users-prompt">
                {activity ? 'Edit Activity' : 'Add Activity'}
                <IconButton className={classes.dialogCloseButton} onClick={() => close()}>
                  <CloseIcon className={classes.dialogCloseIcon} />
                </IconButton>
              </DialogTitle>
              <DialogContent classes={{ root: classes.content }}>
                <Grid>
                  <Grid item className={classes.rowContent}>
                    {activity ? (
                      <div className={classes.sectionTitle}>Volunteer Name: {format.name(user)}</div>
                    ) : (
                      <>
                        <VolunteerSelector
                          volunteers={values.volunteers}
                          onChange={(value: PersonRef[]) => setFieldValue('volunteers', value)}
                          existingVolunteers={[]}
                        />
                        <FormHelperText error={!!errors.volunteers}>{errors.volunteers}</FormHelperText>
                      </>
                    )}
                  </Grid>
                  <Grid item className={classes.rowContent}>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={4}>
                        <StyledKeyboardDatePicker
                          inputVariant="outlined"
                          label="Check-In Date"
                          higher
                          onChange={setDateValue('startDate')}
                          error={!!(touched.startDate && errors.startDate)}
                          helperText={touched.startDate && errors.startDate}
                          value={values.startDate}
                          onBlur={() => setFieldTouched('startDate')}
                          name="startDate"
                          className={classes.input}
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <StyledKeyboardTimePicker
                          inputVariant="outlined"
                          label="Check-In Time"
                          higher
                          onChange={setDateValue('startTime')}
                          error={!!(touched.startTime && errors.startTime)}
                          helperText={touched.startTime && errors.startTime}
                          value={values.startTime}
                          onBlur={() => setFieldTouched('startTime')}
                          name="startTime"
                          className={classes.input}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className={classes.rowContent}>
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={4}>
                        <StyledKeyboardDatePicker
                          inputVariant="outlined"
                          label="Check-Out Date"
                          higher
                          onChange={setDateValue('endDate')}
                          error={!!(touched.endDate && errors.endDate)}
                          helperText={touched.endDate && errors.endDate}
                          value={values.endDate}
                          onBlur={() => setFieldTouched('endDate')}
                          name="endDate"
                          className={classes.input}
                        />
                      </Grid>

                      <Grid item xs={4}>
                        <StyledKeyboardTimePicker
                          inputVariant="outlined"
                          label="Check-Out Time"
                          higher
                          onChange={setDateValue('endTime')}
                          error={!!(touched.endTime && errors.endTime)}
                          helperText={touched.endTime && errors.endTime}
                          value={values.endTime}
                          onBlur={() => setFieldTouched('endTime')}
                          name="endTime"
                          className={classes.input}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item className={classes.rowContentCentered}>
                    <DurationEditField value={values.duration} onChange={setDateValue('duration')} />
                  </Grid>
                  <Grid item className={classes.rowContent}>
                    <AdvancedAutocomplete<Opportunity>
                      label={'Opportunity'}
                      placeholder={'Select an Opportunity'}
                      value={values.opportunity as Opportunity}
                      options={opportunities}
                      getOptionInfo={getOptionInfo}
                      onChange={value => {
                        const opportunityRoles =
                          value !== null ? filterRoles(roles as Role[], value as Opportunity) : []
                        setFieldValue('opportunity', value)
                        if (volunteeringRole && !opportunityRoles.some(({ id }) => id === volunteeringRole.id)) {
                          setFieldValue('volunteeringRole', null)
                        }
                      }}
                      clearBeforeReselect
                    />
                  </Grid>
                  <Grid item className={classes.rowContent}>
                    <AdvancedAutocomplete<Role>
                      label={'Volunteer Role'}
                      placeholder={'Select a Volunteer Role'}
                      value={values.volunteeringRole as Role}
                      options={filterRoles(roles as Role[], values.opportunity as Opportunity)}
                      getOptionInfo={getOptionInfo}
                      onChange={value => setFieldValue('volunteeringRole', value)}
                      clearBeforeReselect
                    />
                  </Grid>
                  {additionalNotesEnabled && (
                    <Grid item className={classes.rowContent}>
                      <StyledInput
                        value={values.team as string}
                        onChange={value => setFieldValue('team', value)}
                        label={'Additional Notes'}
                        placeholder="Additional Notes"
                        error={touched.team ? (errors.team as string) : undefined}
                      />
                    </Grid>
                  )}
                </Grid>
              </DialogContent>
              <DialogActions className={classes.actions}>
                <OutlinedButton disabled={isSubmitting} onClick={close}>
                  Cancel
                </OutlinedButton>
                <ContainedButton disabled={isSubmitting} isLoading={isSubmitting} onClick={submitForm}>
                  {activity ? 'Edit' : 'Add'}
                </ContainedButton>
              </DialogActions>
            </Form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

export default (props: AddOrEditActivityPromptProps) => (
  <ThemeProvider theme={muiTheme}>
    <AddOrEditActivityPrompt {...props} />
  </ThemeProvider>
)
