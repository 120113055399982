import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { isUndefined } from 'lodash'
import StyledInput from './StyledInput'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowDownUp from '@material-ui/icons/KeyboardArrowUp'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import { createPortal } from 'react-dom'
import { useAutocompleteOptionsPositioningStyles } from '../helpers/advanced-autocomplete/useAutocompleteOptionsPositioningStyles'
import useDebounce from '../utils/useDebounce'
import { useHandleBlur } from '../helpers/useHandleBlur'
import { useAdvancedAutocompleteStyles } from '../helpers/advanced-autocomplete/useAdvancedAutocompleteStyles'
import { useHandleScroll } from '../helpers/useHandleScroll'
import { GroupedOptions, useDisplayAreaInfo } from '../helpers/useDisplayAreaInfo'
import cn from 'classnames'
import { useHandleKeyboardEvents } from '../helpers/advanced-autocomplete/useHandleKeyboardEvents'
import LoopIcon from '@material-ui/icons/Loop'
import CloseIcon from '@material-ui/icons/Close'

interface PreparedOption {
  left: string
  right?: string
  id: number | string
}

export interface OnScreenRenderingConfig {
  pageSize?: number
  optionRowHeight: number
  groupTitleHeight: number
  optionsWidth?: number
}

interface AdvancedAutocompleteProps<T = string | { id: number | string; value: string }> {
  label?: string
  options?: T[]
  fetchOptions?: (props?: {
    search: string
    limit: number
    offset: number
  }) => Promise<{ rows: T[]; count?: number; groupCount?: number; time?: number }>
  pageSize?: number
  multiline?: boolean
  onAdd?: () => Promise<any> | any
  addNewText?: string
  disabled?: boolean
  value?: T[] | T | null
  defaultValue?: T[] | T | null
  inputValue?: string | null
  open?: boolean
  onOpenClose?: (opened: boolean) => void
  onChange?: (value: T[] | T | null) => void
  onFocus?: () => void
  onBlur?: () => void
  onInputChange?: (value: string) => void
  groupHandler?: (options: T[]) => GroupedOptions<T>
  getOptionInfo?: (option: T) => PreparedOption
  getOptionDisabled?: (option: T) => boolean
  closeOnSelect?: boolean
  disableOptionsAutoFiltering?: boolean
  onScreenRenderingConfig?: OnScreenRenderingConfig
  noOptionsText?: string
  placeholder?: string
  disableInputFilter?: boolean
  variant?: 'filter' | 'form'
  error?: string
  clearable?: boolean
  clearBeforeReselect?: boolean
  addNewIcon?: JSX.Element
}

const getOptionInfoDefault = (option: any) => {
  if (typeof option === 'string') {
    return { left: option, id: option }
  }
  return { left: option.value as string, id: option.id as string, right: undefined }
}

const getOptionText = ({ left, right }: PreparedOption) => `${left} ${right || ''}`.trim()

export function AdvancedAutocomplete<T>(props: AdvancedAutocompleteProps<T>) {
  const { clearable = true } = props
  const variant = useMemo(() => props.variant || 'form', [props.variant])
  const getOptionInfo = props.getOptionInfo || getOptionInfoDefault
  const { noOptionsText = 'No options', fetchOptions } = props
  const classes = useAdvancedAutocompleteStyles({ variant })
  const containerRef = useRef<HTMLDivElement>(null)
  const optionsContainerRef = useRef<HTMLDivElement>(null)
  const optionsDivRef = useRef<HTMLDivElement>(null)
  const focusedOptionRef = useRef<HTMLDivElement>(null)
  const [focusedOption, setFocusedOption] = useState<T | null>(null)
  const [scrollTopPosition, setScrollTopPosition] = useState(0)
  const [optionsCount, setOptionsCount] = useState(0)
  const [groupCount, setGroupCount] = useState(0)
  const [optionsLoaded, setOptionsLoaded] = useState(!fetchOptions)
  const optionsLoading = useRef<boolean>(false)
  const lastOptionLoadedTime = useRef<number>(0)

  const openControlled = !isUndefined(props.open)
  const [open, setOpenNative] = useState<boolean>(openControlled ? (props.open as boolean) : false)
  const setOpen = useCallback((value: boolean) => {
    setOpenNative(value)
    !value && setScrollTopPosition(0)
  }, [])
  const openValue = openControlled ? (props.open as boolean) : open
  const handleOpenChange = useCallback(
    value => {
      if (!openControlled) {
        setOpen(value)
      }
      props.onOpenClose?.(value)
    },
    [openControlled, props.onOpenClose, setOpen],
  )

  const onBlur = useCallback(() => {
    props.onBlur?.()
    if (!openControlled && open) {
      setOpen(false)
    }
  }, [open, openControlled, props, setOpen])
  const handleBlur = useHandleBlur([containerRef, optionsContainerRef], onBlur)

  const inputControlled = !isUndefined(props.inputValue)
  const getInputValue = useCallback(() => {
    return inputControlled
      ? (props.inputValue as string | null)
      : props.value
      ? getOptionText(getOptionInfo(props.value as T))
      : null
  }, [inputControlled, props.inputValue, props.value, getOptionInfo])
  const [inputValue, setInputValue] = useState<string | null>(getInputValue())
  const inputTextValue = (inputControlled ? (props.inputValue as string | null) : inputValue) || ''
  const handleInputChange = useCallback(
    (value: string) => {
      if (!inputControlled) {
        setInputValue(value)
      }
      props.onInputChange?.(value)
      if (!openControlled && !open && value !== '') {
        setOpen(true)
      }

      if (!value) {
        setValue(null)
        props.onChange?.(null)
      }
    },
    [inputControlled, open, openControlled, props.onChange, props.onInputChange, setOpen],
  )
  const debouncedInput = useDebounce(inputTextValue, 250)
  useEffect(() => {
    const properInputValue = getInputValue()
    if (!inputControlled && inputValue !== properInputValue) {
      setInputValue(properInputValue)
    }
  }, [props.value]) // eslint-disable-line react-hooks/exhaustive-deps

  // Initially loading options on dropdown open
  useEffect(() => {
    if (!optionsLoaded && open && fetchOptions && !optionsLoading.current) {
      optionsLoading.current = true
      fetchOptions({ search: inputTextValue, offset: 0, limit: props.onScreenRenderingConfig?.pageSize || 0 })
        .then(({ rows, count, groupCount }) => {
          props.onScreenRenderingConfig?.pageSize ? setFilteredOptions(rows) : setOptions(rows)
          setFilteredOptions(rows)
          count && setOptionsCount(count)
          groupCount && setGroupCount(groupCount)
          setOptionsLoaded(true)
        })
        .catch(() => {})
        .finally(() => {
          optionsLoading.current = false
        })
    }
  }, [inputTextValue, open, optionsLoaded, props]) // eslint-disable-line react-hooks/exhaustive-deps

  const [options, setOptions] = useState<T[]>(props.options || [])

  const [filteredOptions, setFilteredOptions] = useState<T[]>(options)

  // Applying input filtering
  useEffect(() => {
    // if pageSize is passed - filtering is server side only, to avoid issues with pagination
    if (!props.disableInputFilter && !props.disableOptionsAutoFiltering && !props.onScreenRenderingConfig?.pageSize) {
      setFilteredOptions(
        options.filter(option =>
          getOptionText(getOptionInfo(option)).toLocaleLowerCase().includes(debouncedInput.toLocaleLowerCase()),
        ),
      )
    }
  }, [
    debouncedInput,
    getOptionInfo,
    options,
    props.disableInputFilter,
    props.disableOptionsAutoFiltering,
    props.onScreenRenderingConfig,
  ])

  // Dynamically load options on input when chunked loading is enabled
  useEffect(() => {
    if (props.onScreenRenderingConfig?.pageSize && optionsLoaded) {
      optionsLoading.current = true
      fetchOptions?.({ search: debouncedInput, offset: 0, limit: props.onScreenRenderingConfig.pageSize })
        .then(({ rows, count, groupCount, time }) => {
          if ((time as number) > lastOptionLoadedTime.current) {
            lastOptionLoadedTime.current = time as number
            setFilteredOptions(rows)
            setOptionsCount(count as number)
            setGroupCount(groupCount as number)
          }
        })
        .catch(() => {})
        .finally(() => {
          optionsLoading.current = false
        })
    }
  }, [debouncedInput]) // eslint-disable-line react-hooks/exhaustive-deps

  const optionsStyles = useAutocompleteOptionsPositioningStyles({
    inputDiv: containerRef.current,
    open: openValue,
    optionsDiv: optionsDivRef.current,
    optionsCount: filteredOptions.length,
  })

  const closeOnSelect = isUndefined(props.closeOnSelect) ? true : props.closeOnSelect
  const [value, setValue] = useState<T | T[] | null>(props.defaultValue || null)
  const valueControlled = !isUndefined(props.value)
  const clearBeforeReselect = useMemo(() => {
    if (!props.clearBeforeReselect || props.disabled) return false
    const realValue = valueControlled ? (props.value as T | T[] | null) : value
    if (Array.isArray(realValue)) return false
    return !openValue && realValue !== null
  }, [openValue, props.clearBeforeReselect, props.disabled, props.value, value, valueControlled])
  const handleValueChange = useCallback(
    (option: T) => {
      if (!valueControlled) {
        setValue(option)
      }
      if (!inputControlled) {
        setInputValue(getOptionText(getOptionInfo(option)))
      }
      if (closeOnSelect && !openControlled) {
        setOpen(false)
      }
      props.onChange?.(option)
    },
    [closeOnSelect, getOptionInfo, inputControlled, openControlled, props.onChange, setOpen, valueControlled],
  )

  useEffect(() => {
    if (!isUndefined(props.options)) {
      setOptions(props.options || [])
      if (props.disableInputFilter) {
        setFilteredOptions(props.options || [])
        if (!Array.isArray(props.value) && props.value) {
          setInputValue(getOptionText(getOptionInfo(props.value)))
        }
      }
    }
  }, [getOptionInfo, props.disableInputFilter, props.options]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleScroll = useHandleScroll(setScrollTopPosition)
  const { topPlaceholderHeight, bottomPlaceholderHeight, visibleOptions, visibleGroupedOptions } =
    useDisplayAreaInfo<T>({
      options: filteredOptions,
      scrollTopPosition,
      rowHeight: props.onScreenRenderingConfig?.optionRowHeight || 40,
      height: optionsStyles.maxHeight ? parseInt(optionsStyles.maxHeight as string) : 0,
      groupHandler: props.groupHandler,
      groupTitleHeight: props.onScreenRenderingConfig?.groupTitleHeight,
      groupCount: props.onScreenRenderingConfig?.pageSize ? groupCount : undefined,
      optionsCount: props.onScreenRenderingConfig?.pageSize ? optionsCount : undefined,
    })

  // Chunked options loading on scroll
  useEffect(() => {
    if (
      !optionsLoading.current &&
      props.onScreenRenderingConfig?.pageSize &&
      filteredOptions.length < optionsCount &&
      optionsStyles.maxHeight &&
      fetchOptions
    ) {
      const groupHeadersHeight = groupCount * (props.onScreenRenderingConfig?.groupTitleHeight || 0)
      const loadedOptionsHeight = filteredOptions.length * (props.onScreenRenderingConfig?.optionRowHeight || 0)
      const divHeight = parseInt(optionsStyles.maxHeight as string)
      // loading additional block of options if there is not enough options to fill 2 screens
      if (groupHeadersHeight + loadedOptionsHeight - divHeight - scrollTopPosition < divHeight * 2) {
        optionsLoading.current = true
        fetchOptions({
          search: inputTextValue,
          offset: filteredOptions.length,
          limit: props.onScreenRenderingConfig.pageSize,
        })
          .then(({ rows }) => {
            setFilteredOptions(value => [...value, ...rows])
          })
          .catch(() => {})
          .finally(() => {
            optionsLoading.current = false
          })
      }
    }
  }, [scrollTopPosition]) // eslint-disable-line react-hooks/exhaustive-deps

  const renderOption = useCallback(
    (option: T) => {
      const optionInfo = getOptionInfo(option)
      const isFocused = option === focusedOption
      const isDisabled = props.getOptionDisabled?.(option)
      return (
        <div
          tabIndex={-1}
          className={cn(classes.option, { [classes.focusedOption]: isFocused, [classes.disabledOption]: isDisabled })}
          style={props.onScreenRenderingConfig ? { height: `${props.onScreenRenderingConfig.optionRowHeight}px` } : {}}
          key={optionInfo.id}
          onClick={() => !isDisabled && handleValueChange(option)}
          onMouseOver={() => !isDisabled && setFocusedOption(option)}
          ref={isFocused ? focusedOptionRef : undefined}
        >
          <div className={classes.optionLeft}>{optionInfo.left}</div>
          {optionInfo.right && <div className={classes.optionRight}>{optionInfo.right}</div>}
        </div>
      )
    },
    [
      classes.disabledOption,
      classes.focusedOption,
      classes.option,
      classes.optionLeft,
      classes.optionRight,
      focusedOption,
      getOptionInfo,
      handleValueChange,
      props.getOptionDisabled,
      props.onScreenRenderingConfig,
    ],
  )

  const renderOptions = useCallback(() => {
    const optionsToRender = props.onScreenRenderingConfig ? visibleOptions : filteredOptions
    if (filteredOptions.length === 0) {
      return (
        <div className={cn(classes.option, classes.noOptions)}>
          <div className={classes.optionLeft}>{noOptionsText}</div>
        </div>
      )
    }
    if (props.groupHandler) {
      const groupedOptions = props.onScreenRenderingConfig ? visibleGroupedOptions : props.groupHandler(filteredOptions)
      return Object.keys(groupedOptions).map(group => (
        <div key={group} tabIndex={-1} className={classes.group}>
          <div
            className={classes.groupTitle}
            style={
              props.onScreenRenderingConfig?.groupTitleHeight
                ? { height: `${props.onScreenRenderingConfig.groupTitleHeight}px` }
                : {}
            }
          >
            {group}
          </div>
          {groupedOptions[group].map(option => renderOption(option))}
        </div>
      ))
    } else {
      return optionsToRender.map(option => renderOption(option))
    }
  }, [classes.group, classes.groupTitle, filteredOptions, renderOption, visibleGroupedOptions, visibleOptions]) // eslint-disable-line

  const handleKeyDown = useHandleKeyboardEvents({
    filteredOptions,
    focusedOption,
    onOpenChange: handleOpenChange,
    focusedOptionRef,
    onValueChange: handleValueChange,
    setFocusedOption,
    optionsDivRef,
    dynamicLoadingEnabled: !!props.onScreenRenderingConfig?.pageSize,
    optionsCount,
  })

  return (
    <div className={classes.autocompleteContainer}>
      <StyledInput
        label={props.label}
        value={inputTextValue}
        onChange={handleInputChange}
        endAdornment={
          clearBeforeReselect ? (
            <CloseIcon />
          ) : variant === 'form' ? (
            openValue ? (
              <KeyboardArrowDownUp />
            ) : (
              <KeyboardArrowDownIcon />
            )
          ) : openValue ? (
            <ArrowDropUpIcon />
          ) : (
            <ArrowDropDownIcon />
          )
        }
        onAdornmentClick={() => {
          if (clearBeforeReselect) {
            handleInputChange('')
          } else {
            return props.disabled && handleOpenChange(!openValue)
          }
        }}
        ref={containerRef}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
        onFocus={() => !props.disabled && !clearBeforeReselect && setOpen(true)}
        clearable={clearable && !clearBeforeReselect}
        variant={variant}
        placeholder={props.placeholder}
        error={props.error}
        disabled={props.disabled}
      />
      {openValue &&
        createPortal(
          <div
            className={classes.optionsContainer}
            style={optionsStyles}
            ref={optionsContainerRef}
            tabIndex={-1}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
          >
            <div
              className={cn(classes.options, { [classes.filterVariant]: props.variant === 'filter' })}
              style={{
                maxHeight: props.onAdd ? `calc(${optionsStyles.maxHeight} - 40px)` : optionsStyles.maxHeight,
              }}
              onScroll={props.onScreenRenderingConfig ? handleScroll : undefined}
              ref={optionsDivRef}
            >
              {optionsLoaded ? (
                <>
                  {props.onScreenRenderingConfig && filteredOptions.length > 0 && (
                    <div style={{ height: topPlaceholderHeight }} className={classes.offScreenPlaceholder}></div>
                  )}
                  {renderOptions()}
                  {props.onScreenRenderingConfig && filteredOptions.length > 0 && (
                    <div style={{ height: bottomPlaceholderHeight }} className={classes.offScreenPlaceholder}></div>
                  )}
                </>
              ) : (
                <LoopIcon />
              )}
            </div>
            {props.onAdd && (
              <div className={classes.addNew} onClick={props.onAdd}>
                {props.addNewIcon}
                {props.addNewText}
              </div>
            )}
          </div>,
          document.body,
        )}
    </div>
  )
}

export default AdvancedAutocomplete
