import { Field, Form, Formik } from 'formik'
import { FormModalScreen } from 'components/FormModal'
import React from 'react'
import { useAddEditEventStyles } from 'Event/hooks/useAddEditEventStyles'
import * as yup from 'yup'
import { StyledInput } from 'civic-champs-shared/formik/components/StyledInput'
import { CkEditorStyledField } from '../../civic-champs-shared/formik/components/CkEditorStyledField'

export interface EventAdditionalInformationScreenValues {
  contactEmail: string
  contactName: string
  contactPhoneNumber: string
  instructions: string
}

export interface EventAdditionalInformationScreenProps {
  values: EventAdditionalInformationScreenValues
  onSubmit: (values: EventAdditionalInformationScreenValues, asDraft?: boolean) => void
  isNewEvent: boolean
  onBack: (values: EventAdditionalInformationScreenValues) => void
  isDraftable: boolean
  isSchedulable: boolean
}

const eventAdditionalInformationPayloadSchema = yup.object({
  contactEmail: yup.string().notRequired().nullable(),
  contactName: yup.string().notRequired().nullable(),
  contactPhoneNumber: yup.string().notRequired().nullable(),
  instructions: yup.string().notRequired().nullable(),
})

export const EventAdditionalInformationScreen = ({
  values,
  onSubmit,
  isNewEvent,
  onBack,
  isDraftable,
  isSchedulable,
}: EventAdditionalInformationScreenProps) => {
  const [asDraft, setAsDraft] = React.useState(false)
  const classes = useAddEditEventStyles()
  return (
    <Formik
      initialValues={values}
      validationSchema={eventAdditionalInformationPayloadSchema}
      onSubmit={values => onSubmit(values, asDraft)}
      validateOnChange
      isInitialValid={!isNewEvent}
    >
      {({ submitForm, isSubmitting, values, setFieldValue, errors, touched }) => {
        return (
          <Form>
            <FormModalScreen
              onBack={() => onBack(values)}
              onNext={submitForm}
              onDraft={
                isSchedulable && isDraftable
                  ? () => {
                      setAsDraft(true)
                      submitForm()
                    }
                  : undefined
              }
              draftText={isSchedulable && isDraftable ? 'Finish Later' : undefined}
              nextText="Next"
              backText="Back"
              disabled={isSubmitting}
            >
              <div className={classes.section}>
                <span className={classes.sectionTitle}>Opportunity Details:</span>
                <Field
                  name="instructions"
                  label="Day-of Event Instructions"
                  placeholder="Enter day-of event instructions (this will be sent to volunteers 24 hours before they are scheduled to volunteer)"
                  component={CkEditorStyledField}
                />
              </div>
              <div className={classes.section}>
                <span className={classes.sectionTitle}>Point of Contact: </span>
                <Field
                  component={StyledInput}
                  name="contactName"
                  label={'Name'}
                  placeholder="Enter the Point of Contact’s name"
                />
                <Field
                  component={StyledInput}
                  name="contactEmail"
                  label={'Email'}
                  placeholder="Enter the Point of Contact’s email address"
                />
                <Field
                  component={StyledInput}
                  name="contactPhoneNumber"
                  label={'Phone'}
                  placeholder="Enter the Point of Contact’s phone number"
                />
              </div>
            </FormModalScreen>
          </Form>
        )
      }}
    </Formik>
  )
}
